import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'findLanguageFromKey' })
export class FindLanguageFromKeyPipe implements PipeTransform {
  private languages: { [key: string]: { name: string; rtl?: boolean } } = {
    da: { name: 'Dansk' },
    no: { name: 'Norsk' },
    se: { name: 'Svenska' },
    en: { name: 'English' },
    // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
  };

  transform(lang: string): string {
    return this.languages[lang].name;
  }
}


@Pipe({ name: 'findLanguageFlagFromKey' })
export class FindLanguageFlagFromKeyPipe implements PipeTransform {
  private languages: { [key: string]: { name: string; rtl?: boolean } } = {
    da: { name: '../../../content/images/flag-da.png' },
    no: { name: '../../../content/images/flag-no.png' },
    se: { name: '../../../content/images/flag-se.png' },
    en: { name: '../../../content/images/flag-en.png' }
  };

  transform(lang: string): string {
    return this.languages[lang].name;
  }
}
