import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SupoCaseInvoiceService } from '../services/supo-case-invoice.service';
import { tap } from 'rxjs/operators';
import { SOS_HOME_URL } from '@supoui/shared/constants/application.constants';

@Injectable({
  providedIn: 'root'
})
export class UserInformationRequestRouteResolver  {
  constructor(private supoCaseInvoiceService: SupoCaseInvoiceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const linkId = route.params['linkId'];

    if (linkId) {
      return this.supoCaseInvoiceService.getInformationRequestLinkInfo(linkId).pipe(
        tap(result => {
          if (result === null) {
            document.location.href = SOS_HOME_URL;
          }
        })
      );
    } else {
      return of(null);
    }
  }
}
