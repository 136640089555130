import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';


@Directive()
export class AbstractSubscribeUnsubscribeDirective implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
