import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class Country {
  id: number;
  code: string | null;
  url: string;
  countryName: string;
  name: string;
  phonePrefix: string;

  public constructor(init?: Partial<Country>) {
    Object.assign(this, init);
  }
}

export class CountryDetails {
  id: number;
  code: string | null;
  countryName: string;
  phonePrefix: string;

  public constructor(init?: Partial<CountryDetails>) {
    Object.assign(this, init);
  }

  toCountry(): Country {
    const country = new Country();
    country.id = this.id;
    country.code = this.code;
    country.name = this.countryName;
    return country;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {
  adapt(item: any): Country {
    return new Country({
      id: item.id,
      code: item.countryCodeAlpha2,
      countryName: item.countryName,
      name: item.name,
      url: `/flag-icons/${item.alpha2Code}.svg`.toLocaleLowerCase(),
      phonePrefix: item.phonePrefix
    });
  }

  adaptToCountryDetails(item: any): CountryDetails {
    return new CountryDetails({
      id: item.id,
      code: item.alpha2Code,
      countryName: item.name,
      phonePrefix: item.phonePrefix
    });
  }
  adaptFromCountry(country: Country): CountryDetails {
    return new CountryDetails({
      id: country.id,
      code: country.code,
      countryName: country.name,
      phonePrefix: ''
    });
  }
}
