import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AbstractSubscribeUnsubscribeDirective } from '@supoui/shared/components/abstract-subscribe-unsubscribe-directive';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export class Error {
  // eslint-disable-next-line @typescript-eslint/no-parameter-properties
  constructor(public readonly code: string, public readonly message: string) {}
}

export abstract class AbstractFormGroupComponent extends AbstractSubscribeUnsubscribeDirective {
  public fg: UntypedFormGroup;
  public running: boolean;
  public updated = false;
  public error: any;

  validateAllFormFields(formGroup: UntypedFormGroup): void {
    if (formGroup.enabled && !formGroup.valid) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
          this.validateAllFormFields(control);
        }
      });
    }
  }

  protected handleObservable<T = any>(observable: Observable<T>): void {
    this.running = true;
    const subscription = observable.subscribe(
      response => {
        this.stopped();
        this.updated = true;
        window.setTimeout(() => (this.updated = false), 2500);
        subscription.unsubscribe();
        this.onUpdated(response);
      },
      error => {
        // eslint-disable-next-line no-console
        this.running = false;
        console.log('error', error);
        this.stopped(error);
        this.onError(error);
        throw error;
      }
    );
  }

  protected handleObservableResponse<T = any>(observable: Observable<T>): Observable<T> {
    this.running = true;
    return observable.pipe(
      tap(() => {
        this.stopped();
        this.updated = true;
        window.setTimeout(() => (this.updated = false), 2500);
      }),
      catchError(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        this.stopped(error);
        throw error;
      })
    );
  }

  protected started(): void {
    this.error = null;
    this.running = true;
  }

  protected stopped(error?: any): void {
    this.running = false;
    this.error = error;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onUpdated(response: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onError(error: any): void {}
}
