import { SupplierInvoiceFilterRequest } from './../../economy/models/invoice-filter-request';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpService } from '@supoui/core/services/http.service';
import { SupoCase } from '@supoui/shared/model/supo-case';
import { SupoInvoice } from '@supoui/shared/model/supo-invoice';
import { CaseFilterRequest } from '../../board/components/cases/travel/models/case-filter-request.model';
import { CaseFilterResponse } from '../../board/components/cases/travel/models/case-filter-response.model';
import { BoardCaseAdapter } from '../../board/components/cases/travel/models/board-case.model';
import { UserLock } from '@supoui/shared/model/user-lock';
import { SupoDocument } from '@supoui/shared/model/supo-document';
import { SupoInvoiceFilterResponse } from '@supoui/shared/model/supo-invoice-filter-response';
import { AttachInvoiceDocument } from '@supoui/shared/model/attach-invoice-document';
import { RequestUserInformation } from '@supoui/shared/model/request-user-information';
import { RequestUserInformationLinkInfo } from '@supoui/shared/model/request-user-information-linkinfo';
import { RequestUserInformationResponse } from '@supoui/shared/model/request-user-information-response';
import { StakeholderAdapter } from '@supoui/shared/model/stakeholder';
import { AccountService } from '../auth/account.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy({ checkProperties: true })
export class SupoCaseInvoiceService {
  private cachedSupoCases = new ReplaySubject<SupoCase[]>(1);
  private cachedSupoCasesSize = 0;
  private cachedSupoCasesLoading = false;

  private authSubscription: Subscription;

  constructor(private httpService: HttpService,
              private boardCaseAdapter: BoardCaseAdapter,
              private stakeholderAdapter: StakeholderAdapter,
              private accountService: AccountService) {
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => {
      this.resetCache();
    });
  }

  public getCases(): Observable<SupoCase[]> {
    if (!this.cachedSupoCasesLoading && this.cachedSupoCasesSize === 0) {
      this.cachedSupoCasesLoading = true;
      this.httpService.get<SupoCase[]>(`services/supoinvoice/api/supo-cases`).subscribe(response => {
        this.cachedSupoCasesSize = response.length;
        this.cachedSupoCasesLoading = false;
        this.cachedSupoCases.next(response);
      });
    }
    return this.cachedSupoCases;
  }

  resetCache() {
    this.cachedSupoCasesSize = 0;
  }

  public getCase(id: string): Observable<SupoCase> {
    return this.httpService
      .get(`services/supoinvoice/api/supo-cases/${id}`)
      .pipe(map((c: any) => Object.assign(c, { stakeholders: c.stakeholders.map((s: any) => this.stakeholderAdapter.adapt(s)) })));
  }

  public getBoardCases(caseFilterRequest: CaseFilterRequest, withCccBridgeToken: boolean): Observable<CaseFilterResponse> {
    const filter = {
      pageSize: caseFilterRequest.pageSize,
      pageIndex: caseFilterRequest.pageIndex,
      sortOrder: caseFilterRequest.sortOrder,
      sortBy: caseFilterRequest.sortBy,
      fromDate: caseFilterRequest.filter.fromDate,
      toDate: caseFilterRequest.filter.toDate,
      filteringText: caseFilterRequest.filter.filteringText,
      withCccBridgeToken
    };
    return this.httpService.post<CaseFilterResponse>('services/supoinvoice/api/supo-cases/request', filter).pipe(
      map((data: CaseFilterResponse) => {
        return new CaseFilterResponse({
          boardCaseDtoList: data.boardCaseDtoList.map(boardCaseDto => this.boardCaseAdapter.adapt(boardCaseDto)),
          totalNumber: data.totalNumber
        });
      })
    );
  }

  public createCase(supoCase: Partial<SupoCase>): Observable<SupoCase> {
    supoCase.createdByEndUserRequest = false;
    return this.httpService.post<SupoCase>('services/supoinvoice/api/supo-cases', supoCase).pipe(
      tap(() => this.resetCache())
    );
  }

  public updateCase(supoCase: Partial<SupoCase>): Observable<SupoCase> {
    return this.httpService.put<SupoCase>('services/supoinvoice/api/supo-cases', supoCase).pipe(
      tap(() => this.resetCache())
    );
  }

  public deleteCase(id: string): Observable<Object> {
    return this.httpService.delete(`services/supoinvoice/api/supo-cases/${id}`).pipe(
      tap(() => this.resetCache())
    );
  }

  public getInvoices(): Observable<SupoInvoice> {
    return this.httpService.get(`services/supoinvoice/api/invoices`);
  }

  public getInvoicesFiltered(invoiceFilterRequest: SupplierInvoiceFilterRequest): Observable<SupoInvoiceFilterResponse> {
    return this.httpService.post<SupoInvoiceFilterResponse>('services/supoinvoice/api/invoices/request', invoiceFilterRequest);
  }

  public getSupoInvoiceForCase(caseNumber: string): Observable<SupoInvoice[]> {
    return this.httpService.get<SupoInvoice[]>(`services/supoinvoice/api/invoices/case-reference/${caseNumber}`);
  }

  public getSupoInvoice(id: string): Observable<SupoInvoice> {
    return this.httpService.get<SupoInvoice>(`services/supoinvoice/api/invoices/${id}`);
  }

  public createInvoice(invoice: Partial<SupoInvoice>): Observable<SupoInvoice> {
    return this.httpService.post<SupoInvoice>('services/supoinvoice/api/invoices', invoice);
  }

  public updateInvoice(invoice: Partial<SupoInvoice>): Observable<SupoInvoice> {
    return this.httpService.put<SupoInvoice>('services/supoinvoice/api/invoices', invoice);
  }

  public deleteInvoice(id: string): Observable<Object> {
    return this.httpService.delete(`services/supoinvoice/api/invoices/${id}`);
  }

  public deleteInvoices(ids: number[]): Observable<Object> {
    return this.httpService.post('services/supoinvoice/api/invoices/delete', ids);
  }

  public createLockForCase(id: number): Observable<UserLock> {
    return this.httpService.post<UserLock>('services/supoinvoice/api/user-locks/supo-case/' + id, {});
  }

  public getLockForCase(id: number): Observable<UserLock> {
    return this.httpService.get<UserLock>('services/supoinvoice/api/user-locks/supo-case/' + id, {});
  }

  public releaseLockForCase(id: number): Observable<Object> {
    return this.httpService.delete('services/supoinvoice/api/user-locks/supo-case/' + id);
  }

  public getDocument(documentId: number): Observable<Blob> {
    return this.httpService.getDocument('services/supoinvoice/api/documents/data/', documentId.toString());
  }

  public addDocumentToInsurance(document: SupoDocument, insuranceId: number): Observable<SupoDocument> {
    return this.httpService.post<SupoDocument>('services/supoinvoice/api/documents/insurance/' + insuranceId, document);
  }

  public addDocumentToInsuranceUnauthorized(document: SupoDocument, insuranceId: number, linkId: string): Observable<SupoDocument> {
    if (linkId) {
      return this.httpService.post<SupoDocument>(
        `services/supoinvoice/api/documents/insurance-unauthorized/${linkId}/${insuranceId}`,
        document
      );
    } else {
      return this.httpService.post<SupoDocument>(`services/supoinvoice/api/documents/insurance-unauthorized/${insuranceId}`, document);
    }
  }

  public addDocumentToConsent(document: SupoDocument, consentId: number): Observable<SupoDocument> {
    return this.httpService.post<SupoDocument>('services/supoinvoice/api/documents/consent/' + consentId, document);
  }

  public addDocumentToMedical(document: SupoDocument, medicalId: number): Observable<SupoDocument> {
    return this.httpService.post<SupoDocument>('services/supoinvoice/api/documents/medical/' + medicalId, document);
  }

  public addDocumentToInvoice(document: SupoDocument, invoiceId: number): Observable<SupoDocument> {
    return this.httpService.post<SupoDocument>('services/supoinvoice/api/documents/invoice/' + invoiceId, document);
  }

  public sendToCCCBridge(supoCase: SupoCase, isAuthorized: boolean, channel?: string): Observable<SupoCase> {
    const url = 'api/draft-case/submit' + (channel ? `/${channel}` : '');
    if (!isAuthorized) {
      throw new Error('unauthorized submit not accepted here.');
    }
    return this.httpService.post<SupoCase>(url, supoCase);
  }

  public sendToCCCBridgeAndApprove(supoCase: SupoCase, isAuthorized: boolean): Observable<SupoCase> {
    let url;
    if (isAuthorized) {
      url = 'api/draft-case/submit-approve';
    } else {
      url = 'api/draft-case/unauthorized-submit-approve';
    }

    return this.httpService.post<SupoCase>(url, supoCase);
  }

  public approveCase(supoCase: SupoCase, isAuthorized: boolean): Observable<SupoCase> {
    const url = 'api/draft-case/approve';
    if (!isAuthorized) {
      throw new Error('unauthorized submit not accepted here.');
    }
    return this.httpService.post<SupoCase>(url, supoCase);
  }

  public sendToFinanceBridge(supoInvoice: SupoInvoice): Observable<SupoInvoice> {
    return this.httpService.put<SupoInvoice>('services/supoinvoice/api/invoices/submit', supoInvoice);
  }

  public addDocumentToCase(invoiceDocument: AttachInvoiceDocument): Observable<AttachInvoiceDocument> {
    return this.httpService.put<AttachInvoiceDocument>('services/supoinvoice/api/invoices/add-to-case', invoiceDocument);
  }

  public createUserInformationCaseUnauthorized(supoCase: Partial<SupoCase>, linkId: string): Observable<SupoCase> {
    supoCase.createdByEndUserRequest = true;
    if (linkId) {
      return this.httpService.post<SupoCase>(`services/supoinvoice/api/supo-cases-unauthorized/${linkId}`, supoCase).pipe(
        tap(() => this.resetCache())
      );
    } else {
      return this.httpService.post<SupoCase>(`services/supoinvoice/api/supo-cases-unauthorized`, supoCase).pipe(
        tap(() => this.resetCache())
      );
    }
  }

  public sendInformationRequestToEnduser(requestUserInformation: RequestUserInformation): Observable<RequestUserInformationResponse> {
    return this.httpService
      .post<RequestUserInformationResponse>(`services/supoinvoice/api/user-information/send-request`, requestUserInformation)
      .pipe(
        tap(result => {
          if (!result.success) {
            throw 'Send sms failed.';
          }
        })
      );
  }

  public getInformationRequestLinkInfo(linkId: string): Observable<RequestUserInformationLinkInfo> {
    return this.httpService.get<RequestUserInformationLinkInfo>(`services/supoinvoice/api/user-information/unauthorized/${linkId}`);
  }
}
