import { BoardCase } from './board-case.model';

export class CaseFilterResponse {
  totalNumber: number;
  boardCaseDtoList: BoardCase[];

  public constructor(init?: Partial<CaseFilterResponse>) {
    Object.assign(this, init);
  }
}
