import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountRoleService } from './account-role.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class UserRouteRoleAccessService  {
  constructor(private router: Router, public accountRoleService: AccountRoleService, private logger: NGXLogger) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // this.logger.debug('CAN ACTIVATE GUARD: ', state.url);

    return this.accountRoleService.getRoleState().pipe(
      map(() => {
        return this.isRouteAllowed(state);
      })
    );

    // return of(this.isRouteAllowed(state));
  }

  private isRouteAllowed(state: RouterStateSnapshot): boolean {
    let canActivate = false;
    switch (true) {
      case state.url.includes('/case/'):
        canActivate = this.accountRoleService.isCasesActive();

        switch (canActivate) {
          case state.url.includes('/case-summary'):
            canActivate = !!this.accountRoleService.roles.canViewTravelCasesCaseSummaryInformation;
            break;

          case state.url.includes('/attachments'):
            canActivate =
              !!this.accountRoleService.roles.canViewTravelCasesAttachmentInformation ||
              !!this.accountRoleService.roles.canViewTravelCasesAttachmentInformationExtended;
            break;

          case state.url.includes('/medical'):
            canActivate =
              !!this.accountRoleService.roles.canViewTravelCasesMedicalInformation ||
              !!this.accountRoleService.roles.canViewTravelCasesMedicalInformationExtended;
            break;

          case state.url.includes('/economy'):
            canActivate = !!this.accountRoleService.roles.canViewTravelCasesFinancielInformation;
            break;

          case state.url.includes('/supplier-notes'):
            canActivate = !!this.accountRoleService.roles.canViewTravelCasesCustomerNotesInformation;
            break;
        }
        break;

      case state.url.includes('/cases/'):
        canActivate = this.accountRoleService.isCasesActive();

        switch (canActivate) {
          case state.url.includes('/travel'):
            canActivate = !!this.accountRoleService.roles.canViewTravelCases;
            break;

          case state.url.includes('/mobility'):
            canActivate = !!this.accountRoleService.roles.canViewMobilityCases;
            break;

          case state.url.includes('/health'):
            canActivate = !!this.accountRoleService.roles.canViewHealthCases;
            break;
        }
        break;

      case state.url.includes('/insights/'):
        canActivate = this.accountRoleService.isInsightsActive();
        break;

      case state.url.includes('/economy/'):
        canActivate = this.accountRoleService.isEconomyActive();
        break;

      case state.url.includes('/products-contracts/'):
        canActivate = this.accountRoleService.isProductsAndContractsActive();
        break;

      case state.url.includes('/major-incidents/'):
        canActivate = this.accountRoleService.isMajorIncidentsActive();
        break;

      case state.url.includes('/settings/'):
        switch (true) {
          case state.url.includes('/roles'):
            canActivate = !!this.accountRoleService.roles.canManageRoles;
            break;

          case state.url.includes('/user-management'):
            canActivate = !!this.accountRoleService.roles.canManageUsers;
            break;
        }
        break;
    }

    return canActivate;
  }
}
