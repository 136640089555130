import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RequestUserInformationLinkInfo } from '@supoui/shared/model/request-user-information-linkinfo';

@Injectable({
  providedIn: 'root'
})
export class EndUserNewCaseRequestRouteResolver  {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const supplierProfileCode = route.params['spCode'];

    if (supplierProfileCode) {
      const endUserNewCaseInfo: Partial<RequestUserInformationLinkInfo> = {
        supplierProfileCode
      };
      return of(endUserNewCaseInfo);
    } else {
      return of(null);
    }
  }
}
