<form [formGroup]="fg" (ngSubmit)="fg.valid ? save() : validateAllFormFields(fg)">
    <div class="modal-content">
        <div class="modal-header">
            <div fxFill fxLayout="column">
                <div class="modal-header-top-bar" fxFill fxLayout="row">
                    <div fxFlex="50" fxLayoutAlign="start">
                        <h3>{{modalDialogConfiguration.header | translate}}</h3>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="end">
                        <div class="modal-close">
                            <img class="cross-close" src="/content/icons/Close-Black.svg" (click)="activeModal.close(false)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body" *ngIf="modalDialogConfiguration.isBody">
            <div class="request-account-details">
                <app-error-message [errorMessage]="error"></app-error-message>
                <div fxLayout="column" fxLayoutGap="20px">
                    <p>SOS International's travel assistance is carried out in collaboration with a worldwide medical network of carefully selected suppliers and partners including hospitals, clinics, ambulance companies, foreign assistance companies and embassies.</p>
                    <p>Our network is present all over the world and we thoroughly evaluate, rate and quality assure all our partners to ensure the best possible quality treatment to the end-users and the best possible claims cost level for our customers.</p>
                    <p>Would you like to be part of our comprehensive medical network?</p>
                    <p>For enquiries regarding partnerships, business opportunities and questions in general, please submit you contact details here:</p>
                    <div *ngIf="requestAccountCompleted">
                        <p>Contact details received.</p>
                        <button class="primary" mat-stroked-button type="button" (click)="activeModal.close(false)">{{'Close' | translate}}</button>
                    </div>
                    <div *ngIf="!requestAccountCompleted">
                        <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                            <div>
                                <div class="title">{{'Company' | translate}}</div>
                                <div>
                                    <input matInput formControlName="title">
                                    <app-form-control-error-message [control]="fg.get('title')" labelKey="Company"></app-form-control-error-message>
                                </div>
                            </div>
                            <div>
                                <div class="title">{{'First name' | translate}}</div>
                                <div>
                                    <input matInput formControlName="firstName">
                                    <app-form-control-error-message [control]="fg.get('firstName')" labelKey="First name"></app-form-control-error-message>
                                </div>
                            </div>
                            <div>
                                <div class="title">{{'Last name' | translate}}</div>
                                <div>
                                    <input matInput formControlName="lastName">
                                    <app-form-control-error-message [control]="fg.get('lastName')" labelKey="Last name"></app-form-control-error-message>
                                </div>
                            </div>
                            <div>
                                <div class="title">{{'E-mail' | translate}}</div>
                                <div>
                                    <input matInput formControlName="email">
                                    <app-form-control-error-message [control]="fg.get('email')" labelKey="E-mail"></app-form-control-error-message>
                                </div>
                            </div>
                            <div>
                                <div class="title">{{'Phone' | translate}}</div>
                                <div>
                                    <input matInput formControlName="mobilePhone">
                                    <app-form-control-error-message [control]="fg.get('mobilePhone')" labelKey="Phone"></app-form-control-error-message>
                                </div>
                            </div>
<!--                            <div>-->
<!--                                <div class="title">{{'Language' | translate}}</div>-->
<!--                                <div class="common-drop-down common-drop-down-arrow drop-down-input">-->
<!--                                    <ng-select class="custom" [items]="languages" bindValue="value" bindLabel="label" formControlName="language" [clearable]="false" [searchable]="false" [multiple]="false" dropdownPosition="top">-->
<!--                                    </ng-select>-->
<!--                                    <app-form-control-error-message [control]="fg.get('language')" labelKey="Language"></app-form-control-error-message>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="modalDialogConfiguration.isFooter && !requestAccountCompleted">
            <div fxLayout="row" fxLayoutAlign="end">
                <button class="primary" mat-stroked-button type="submit" [disabled]="running">{{modalDialogConfiguration.buttonText | translate}}</button>
            </div>
        </div>
    </div>
</form>
