import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@supoui/core/services/http.service';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption, SearchWithPagination } from '@supoui/shared/util/request-util';
import { IUser } from './user.model';
import { CustomerProfiles } from '@supoui/shared/model/customerProfiles';
import { SupplierProfile } from '@supoui/shared/model/supplier-profile';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UserService {
  public resourceUrl = SERVER_API_URL + 'services/onesosuaa/api/users';
  public resourceQueryUrl = SERVER_API_URL + 'services/onesosuaa/api/users-by-type/SUPPLIER';

  private _avatarImageChanged = new BehaviorSubject<boolean>(false);
  public readonly avatarImageChanged$ = this._avatarImageChanged.asObservable();

  constructor(private http: HttpClient, private http2: HttpService) {}

  register(user: IUser): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/register`, user);
  }

  create(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.resourceUrl, user);
  }

  update(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.resourceUrl, user);
  }

  find(login: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.resourceUrl}/${login}`);
  }

  findAll(logins: string[]): Observable<IUser[]> {
    const data = logins.map(login => {
      return this.find(login);
    });
    return forkJoin([...data]);
  }

  query(req?: SearchWithPagination): Observable<HttpResponse<IUser[]>> {
    const options = createRequestOption(req);
    return this.http.get<IUser[]>(this.resourceQueryUrl, { params: options, observe: 'response' });
  }

  public avatarUrl(login: string, forceRefresh?: boolean): string {
    return SERVER_API_URL + `${this.resourceUrl}/${login}/user-images${forceRefresh ? `?t=${moment.now()}` : ''}`;
  }

  public randomAvatarUrl(): string {
    return SERVER_API_URL + '/api/avatar/png';
  }

  public uploadAvatarImage(login: string, uploadAvatarImageData: FormData): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/${login}/user-images/upload`, uploadAvatarImageData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public uploadAvatarImageBase64(login: string, uploadAvatarImageData: string): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/${login}/user-images/upload-base64-png`, uploadAvatarImageData);
  }

  avatarImageChanged(): void {
    this._avatarImageChanged.next(true);
  }

  delete(login: string): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${login}`);
  }

  authorities(): Observable<string[]> {
    return this.http.get<string[]>(SERVER_API_URL + 'services/onesosuaa/api/users/authorities');
  }

  customerProfiles(bypassSelectedCustomerProfiles = false): Observable<CustomerProfiles[]> {
    return this.http.get<CustomerProfiles[]>(SERVER_API_URL + 'services/onesosuaa/api/customer-profiles').pipe(
      map(profiles => {
        if (bypassSelectedCustomerProfiles) {
          return profiles;
        } else {
          const selectedCustomerProfileCodes = localStorage.getItem('selected-customer-profile-codes')?.split(',');
          return profiles.filter(
            profile => !selectedCustomerProfileCodes?.length || selectedCustomerProfileCodes.includes(profile.customerProfileCode)
          );
        }
      })
    );
  }

  supplierProfiles(bypassSelectedSupplierProfiles = false): Observable<SupplierProfile[]> {
    return this.http.get<SupplierProfile[]>(SERVER_API_URL + 'services/onesosuaa/api/supplier-profiles-current').pipe(
      map(profiles => {
        const typedProfiles = profiles.map(profile => new SupplierProfile(profile))
        if (bypassSelectedSupplierProfiles) {
          return typedProfiles;
        } else {
          const selectedSupplierProfileCodes = localStorage.getItem('selected-supplier-profile-codes')?.split(',');
          return typedProfiles.filter(
            profile => !selectedSupplierProfileCodes?.length || selectedSupplierProfileCodes.includes(profile.supplierProfileCode)
          );
        }
      })
    );
  }

  syncProfiles(): Observable<void> {
    return this.http.get<void>(SERVER_API_URL + 'services/onesosuaa/api/customer-profiles/sync');
  }
}
