import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  closeSidenav: BehaviorSubject<any> = new BehaviorSubject(false);
  toggleSidenav: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {}

  close(): void {
    this.closeSidenav.next(true);
  }

  toggle(): void {
    this.toggleSidenav.next(true);
  }
}
