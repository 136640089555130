import { Injectable } from '@angular/core';
import { Adapter } from '@supoui/shared/interfaces/adapter';
import { AcStatusEnum } from '@supoui/shared/model/enumerations/acstatus.enum';
import moment from 'moment';

/*
    private Long caseId;
    private String caseStatus;
    private String endUserFullName;
    private String customerProfileName;
    private LocalDateTime caseCreatedOn;
    private String caseNumber;
    private String supplierReference;
    private LocalDateTime lastGopSentDateTime;
    private String lastCommitmentType;
    private String invoiceStatus;
*/

export class BoardCase {
  caseId: number;
  caseStatus: string;
  endUserFullName: string;
  customerProfileName: string;
  caseCreatedOn: Date;
  caseNumber: string;
  supplierReferences: string;
  supplierProfileCodes: string;
  lastGopSentDateTime: Date;
  lastCommitmentType: string;
  invoiceStatus: string;
  incidentLocation: string;
  createdByEndUserRequest: boolean;
  origin: string;

  /* OBSOLETE */
  teamName: string;
  customerReference: string;
  policyReference: string;
  policyNumber: string;
  policyProductName: string;
  cause: string;
  assignee: string;
  createdOn: Date;
  incidentId: number;
  incidentDate: Date;
  incidentLongitude: number;
  incidentLatitude: number;
  reserve: number;
  acStatus: AcStatusEnum;
  countryCodeAlpha2: string;
  customerProduct: number;
  customerProductName: string;
  customerProfileId: number;
  currencyCode: string;
  age: number;
  billingCurrency: string;

  public constructor(init?: Partial<BoardCase>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class BoardCaseAdapter implements Adapter<BoardCase> {
  adapt(item: any): BoardCase {
    return new BoardCase({
      caseId: item.caseId,
      caseStatus: item.caseStatus,
      endUserFullName: item.endUserFullName,
      customerProfileName: item.customerProfileName,
      caseCreatedOn: item.caseCreatedOn ? moment.utc(item.caseCreatedOn).toDate() : undefined,
      caseNumber: item.caseNumber,
      supplierReferences: item.supplierReferences,
      supplierProfileCodes: item.supplierProfileCodes,
      lastGopSentDateTime: item.lastGopSentDateTime ? moment.utc(item.lastGopSentDateTime).toDate() : undefined,
      lastCommitmentType: item.lastCommitmentType,
      invoiceStatus: item.invoiceStatus,
      incidentLocation: item.incidentLocation,
      createdByEndUserRequest: item.createdByEndUserRequest,
      origin: item.origin
    });
  }
}
