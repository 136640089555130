import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CaseService } from '@supoui/core/services/case.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-case-redirector',
  templateUrl: 'case-redirector.component.html'
})
@UntilDestroy({ checkProperties: true })
export class CaseRedirectorComponent implements OnInit {
  subscription: Subscription;
  error: string;

  constructor(private caseService: CaseService, private route: ActivatedRoute, private router: Router, private logger: NGXLogger) {}

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      const id = params.id;
      const isCaseNumber = id.match(/^[A-Za-z]{4}-\d{3}-\d{3}-\d{3}$/gi);

      if (isCaseNumber) {
        this.caseService.getCase(id).subscribe(
          seccaCase => this.redirectToCase(seccaCase.id),
          error => (this.error = error)
        );
      } else {
        this.redirectToCase(id);
      }
    });
  }

  redirectToCase(caseId: string): void {
    // Navigate to case and transfer case id
    this.router.navigate(['case', caseId, 'case-basics'], { state: { backUrl: '/home' } });
  }
}
