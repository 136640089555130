import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SupoCaseInvoiceService } from '../services/supo-case-invoice.service';

@Injectable({
  providedIn: 'root'
})
export class DraftCaseRouteResolver  {
  constructor(private supoCaseInvoiceService: SupoCaseInvoiceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let draftId = route.params['id'];

    if (draftId) {
      return this.supoCaseInvoiceService.getCase(draftId);
    } else {
      return of(null);
    }
  }
}
