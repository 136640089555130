import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { Authority } from './shared/constants/authority.constants';
import { UserRouteAccessService } from './core/auth/user-route-access-service';
import { UserRouteRoleAccessService } from './core/auth/user-route-role-access-service';
import { AuthComponent } from './layouts/auth/auth.component';
import { MainComponent } from './layouts/main/main.component';
import { LoginModalComponent } from './shared/login/login.component';
import { ActivateComponent } from './account/activate/activate.component';
import { PasswordResetInitComponent } from './account/password-reset/init/password-reset-init.component';
import { PasswordResetFinishComponent } from './account/password-reset/finish/password-reset-finish.component';
import { CaseRedirectorComponent } from './shared/redirector/case-redirector.component';
import { DraftCaseRouteResolver } from './core/resolvers/draft-case-route-resolver';
import { UserInformationRequestRouteResolver } from './core/resolvers/user-information-request-route-resolver';
import { EndUserNewCaseRequestRouteResolver } from './core/resolvers/end-user-new-case-request-route.resolver';
import { DEBUG_INFO_ENABLED } from './app.constants';

const LAYOUT_ROUTES = [...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: MainComponent,
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: 'home'
            },
            {
              path: 'home',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./home/home.module').then(m => m.OnesosuiHomeModule) }]
            },
            {
              path: 'account',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) }]
            },
            {
              path: 'cases',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: '', loadChildren: () => import('./cases/cases.module').then(m => m.OnesosuiCasesModule) }]
            },
            {
              path: 'new-case-unauthorized',
              children: [{ path: '', loadChildren: () => import('./new-case/new-case.module').then(m => m.OnesosuiNewCaseModule) }]
            },
            {
              path: 'user-information-unauthorized/:linkId',
              resolve: {
                userInformationRequest: UserInformationRequestRouteResolver
              },
              children: [{ path: '', loadChildren: () => import('./new-case/new-case.module').then(m => m.OnesosuiNewCaseModule) }]
            },
            {
              path: 'end-user-new-case/:spCode',
              resolve: {
                userInformationRequest: EndUserNewCaseRequestRouteResolver
              },
              children: [{ path: '', loadChildren: () => import('./new-case/new-case.module').then(m => m.OnesosuiNewCaseModule) }]
            },
            {
              path: 'new-case',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./new-case/new-case.module').then(m => m.OnesosuiNewCaseModule) }]
            },
            {
              path: 'draft-case/:id',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              resolve: {
                draftCase: DraftCaseRouteResolver
              },
              children: [{ path: '', loadChildren: () => import('./new-case/new-case.module').then(m => m.OnesosuiNewCaseModule) }]
            },
            {
              path: 'invoice',
              // data: {
              //   authorities: [Authority.USER, Authority.ADMIN]
              // },
              // canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./invoice/invoice.module').then(m => m.OnesosuiInvoiceModule) }]
            },
            {
              path: 'insights',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: '', loadChildren: () => import('./insights/insights.module').then(m => m.OnesosuiInsightsModule) }]
            },
            {
              path: 'case',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: ':id', loadChildren: () => import('./case/case.module').then(m => m.OnesosuiCaseModule) }]
            },
            {
              path: 'products-contracts',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./products-contracts/products-contracts.module').then(m => m.OnesosuiProductsContractsModule),
                  canActivate: [UserRouteRoleAccessService]
                }
              ]
            },
            {
              path: 'major-incidents',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./major-incidents/major-incidents.module').then(m => m.OnesosuiMajorIncidentsModule)
                }
              ]
            },
            {
              path: 'economy',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./economy/economy.module').then(m => m.OnesosuiEconomyModule)
                }
              ]
            },
            {
              path: 'settings',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./settings/settings.module').then(m => m.OnesosuiSettingsModule) }]
            },
            {
              path: 'batch-billing',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                { path: '', loadChildren: () => import('./batch-billing/batch-billing.module').then(m => m.OnesosuiBatchBillingModule) }
              ]
            },
            {
              path: 'contact',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./contact/contact.module').then(m => m.OnesosuiContactModule) }]
            } /*,
            {
              path: 'help',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./help/help.module').then(m => m.OnesosuiHelpModule) }]
            }*/
          ]
        },

        {
          path: 'auth',
          component: AuthComponent,
          children: [
            {
              path: 'sign-in',
              component: LoginModalComponent
            },
            {
              path: 'activate',
              component: ActivateComponent
            },
            {
              path: 'reset/request',
              component: PasswordResetInitComponent
            },
            {
              path: 'reset/finish',
              component: PasswordResetFinishComponent
            }
          ]
        },
        // Route for specifying case-basic route as fx. /SECC-000-000-020
        {
          path: ':id',
          component: CaseRedirectorComponent
        },
        // Fallback when no prior routes is matched
        { path: '**', redirectTo: '/auth/sign-in', pathMatch: 'full' },

        ...LAYOUT_ROUTES
      ],
      { enableTracing: DEBUG_INFO_ENABLED }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
