import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private sessionStorage: SessionStorageService, private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const s = JSON.parse(JSON.stringify(event));
        if (s.urlAfterRedirects === '/home' || s.urlAfterRedirects === '/auth/sign-in') {
          this.sessionStorage.store('AIM_MESSAGE', true);
        } else this.sessionStorage.store('AIM_MESSAGE', false);
      }
    });
  }
}
