<jhi-info-bar [pageType]="frontpage"></jhi-info-bar>
<div class=" ms-3 ps-5 pt-2">
    <img src="/content/images/sos_logo_primary.png" width="137" height="35">
</div>
<div class="title-fixed">
    <h2 class="modal-title">Welcome to SOS International's</h2>
    <h2 class="modal-title">Supplier Portal</h2>
</div>
<div class="full-width-image"></div>
<div>
    <div class="d-flex h-100">
        <router-outlet></router-outlet>
    </div>
</div>
