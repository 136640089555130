import moment from 'moment';

export interface ICaseRegularMedicalAssessment {
  id: number;
  seccaCaseId: number;
  sourceList: IMedicalAssessmentSource[];
  actionList: IMedicalAssessmentAction[];
  documentList: IMedicalAssessmentDocument[];
  note: IMedicalAssessmentNote;
  visits: IMedicalAssessmentVisits;
  admissionList: IMedicalAssessmentAdmission[];
  admissionIsReadmission: boolean;
  medicalEvacuation: boolean;
  medicalEvacuationDate: moment.Moment;
  travelHomeAsPlanned: boolean;
  repatriationInstructionsAllowed: boolean;
  followupPlanned: boolean;
  followupDate: moment.Moment;
  followupDescription: string;
  repatriationPrognosis: IMedicalAssessmentRepatriationPrognosis;
  repatriationInstructionsBase: IRepatriationInstructionsBase;
  medicalAssessmentType: AssessmentTypeEnum;
  diagnosisItems: IMedicalHistoryIcd10[];
  userInfo: IMedicalHistoryUser;
  createdOn: Date;
  createdBy: string;
  deletedOn: Date | null;
  deletedBy: string;
}

export interface IMedicalAssessmentSource {
  id: number;
  basedOn: AssessmentBasesOn;
  sourceName: string;
  sourceType: string;
}

export interface IMedicalAssessmentAction {
  id: number;
  actionType: MedicalActionTypeEnum;
  date: moment.Moment;
  description: string;
  examinationType: MedicalExaminationsTypeEnum;
  toBeDeleted: boolean;
}

export interface IMedicalAssessmentDocument {
  documentId: string;
}

export interface IMedicalAssessmentVisits {
  id: number;
  numberOfVisits: number;
  numberOfAdditionalVisits: number;
  remarks: string;
}

export interface IMedicalAssessmentAdmission {
  id: number;
  admissionFrom: moment.Moment;
  admissionTo: moment.Moment;
  additionallyAdmissionUntil: moment.Moment;
  admissionType: MedicalAdmissionTypeEnum;
}

export interface IMedicalAssessmentRepatriationPrognosis {
  date: moment.Moment;
  repatriationForm: RepatriationFormEnum;
  oxygen: boolean;
}

export interface IRepatriationInstructionsBase {
  repatriationInstructionId: number;
  repatriationForm: IRepatriationForm;
  repatriationAlternativeForms: IRepatriationAlternativeForm[];
  patientState: IPatientState;
  patientNeeds: IPatientNeeds;
  treatingDoctorName: string;
  treatingDoctorType: string;
}

export interface IRepatriationForm {
  repatriationDate: moment.Moment;
  arrivalDeadline: moment.Moment;
  arrivalDescription: string;
  form: string;
  mobilization: string;
  seaLevelFlight: boolean;
  patientWeight: number;
  patientHeight: number;
  groundRepatriation: boolean;
  coTransport: boolean;
  coTransportDescription: string;
  transportToAirport: TransitEnum;
  transportFromAirport: TransitEnum;
  patientCarriableOnBoard: boolean;
  extraSpaces: IRepatriationExtraSpace[];
}

export interface IRepatriationAlternativeForm {
  repatriationForm: RepatriationFormEnum;
  date: moment.Moment;
  extraSpaces: IRepatriationExtraSpace[];
}

export interface IRepatriationExtraSpace {
  type: ExtraSpaceEnum;
  otherItemDescription: string;
}

export interface IPatientState {
  id: number;
}

export interface IPatientNeeds {
  id: number;
}

export interface ICaseMedicalHistory {
  id: number;
  medicalHistoryType: MedicalHistoryType;
  assessmentBaseUpon: AssessmentBasesOn;
  medicalAssessmentType: AssessmentTypeEnum;
  repatriationInstructions: boolean;
  repatriationPrognosis: boolean;
  sourceText: string;
  diagnosis: string;
  diagnosisItems: IMedicalHistoryIcd10[];
  symptoms: string;
  symptomsItems: IMedicalHistoryIcd10[];
  createdOn: Date;
  createdBy: string;
  deletedOn: Date;
  userInfo: IMedicalHistoryUser;
  note: IMedicalAssessmentNote;
}

export interface IMedicalHistoryIcd10 {
  idc10Code: string;
  idc10Description: string;
}

export interface IMedicalHistoryUser {
  userId: number;
  userActive: boolean;
  userInitial: string;
  userFullName: string;
  imageIsPresent: boolean;
}

export interface IMedicalAssessmentNote {
  id: number;
  assessmentId: number;
  date: moment.Moment;
  status: string;
  internalNote: string;
  mainDiagnosis: string;
  diagnosis: IIcd10Code[];
  symptoms: IIcd10Code[];
}

export interface IIcd10Code {
  icd10Code: string;
  shortName: string;
  fullName: string;
  selected: boolean;
}

export enum AssessmentBasesOn {
  medicalReport = 'MEDICAL_REPORT',
  phoneCall = 'PHONE_CALL',
  medicalHistoryJournal = 'MEDICAL_HISTORY_JOURNAL',
  acScreening = 'AC_SCREENING',
  caseSummary = 'CASE_SUMMARY'
}

export enum AssessmentTypeEnum {
  REGULAR = 'REGULAR',
  LIGHT = 'LIGHT',
  NIGHT = 'NIGHT',
  NONE = 'NONE'
}

export enum MedicalHistoryType {
  regularMedicalAssessment = 'REGULAR_MEDICAL_ASSESSMENT',
  acScreening = 'AC_SCREENING',
  medicalComment = 'MEDICAL_COMMENT'
}

export enum MedicalActionTypeEnum {
  X_RAY = 'X_RAY',
  CT_SCAN = 'CT_SCAN',
  MR_SCAN = 'MR_SCAN',
  ULTRASOUND_SCAN = 'ULTRASOUND_SCAN',
  SURGERY = 'SURGERY',
  GASTROSCOPY = 'GASTROSCOPY',
  COLONOSCOPY = 'COLONOSCOPY',
  ECHO = 'ECHO',
  CORONARY_ANGIOGRAPHY = 'CORONARY_ANGIOGRAPHY',
  ANGIOPLASTY = 'ANGIOPLASTY',
  TREADMILL_TEST = 'TREADMILL_TEST',
  DOPPLERSCAN = 'DOPPLERSCAN',
  EEG = 'EEG',
  WOUND_DRESSING = 'WOUND_DRESSING',
  OTHER = 'OTHER'
}

export enum MedicalExaminationsTypeEnum {
  ACUTELY = 'ACUTELY',
  NOT_ACUTELY = 'NOT_ACUTELY'
}

export enum MedicalAdmissionTypeEnum {
  REGULAR = 'REGULAR',
  INTENSIVE = 'INTENSIVE'
}

export enum RepatriationFormEnum {
  INTENSIVE_CARE_PATIENT = 'INTENSIVE_CARE_PATIENT',
  STRETCHER = 'STRETCHER',
  EXTRA_SPACE = 'EXTRA_SPACE',
  SITTING_SICK_IN_ECONOMY_CLASS_SEAT = 'SITTING_SICK_IN_ECONOMY_CLASS_SEAT',
  REGULAR_PASSENGER = 'REGULAR_PASSENGER',
  TOO_SOON_TO_ESTIMATE = 'TOO_SOON_TO_ESTIMATE'
}

export enum ExtraSpaceEnum {
  SEAT_IN_FRONT = 'SEAT_IN_FRONT',
  SIDE_BY_SIDE_TWO = 'SIDE_BY_SIDE_TWO',
  SIDE_BY_SIDE_TREE = 'SIDE_BY_SIDE_TREE',
  RECLINING_SEAT = 'RECLINING_SEAT',
  OTHER = 'OTHER'
}

export enum TransitEnum {
  GROUND_TRANSPORT = 'GROUND_TRANSPORT',
  LIFT_VAN = 'LIFT_VAN',
  AMBULANCE = 'AMBULANCE'
}

export enum WheelchairNeedEnum {
  CARRY_TO_RAMP = 'CARRY_TO_RAMP',
  CARRY_UP_AND_DOWN_STEP = 'CARRY_UP_AND_DOWN_STEP',
  CARRY_TO_CHAIR = 'CARRY_TO_CHAIR',
  NO = 'NO'
}
