<div class="password-reset-container">
    <div class="col-md-12 pl-0">
        <h5 jhiTranslate="reset.finish.title">Reset password</h5>
    </div>

    <div class="row justify-content-center password-reset-container-inner">
        <div class="col-md-12 pt-2">
            <div class="alert alert-danger" jhiTranslate="reset.finish.messages.keymissing" *ngIf="initialized && !key">
                <strong>The password reset key is missing.</strong>
            </div>

            <div class="alert alert-warning" *ngIf="key && !success">
                <span jhiTranslate="reset.finish.messages.info">Choose a new password</span>
            </div>

            <div class="alert alert-danger" *ngIf="error">
                <span jhiTranslate="reset.finish.messages.error">Your password couldn't be reset. Remember a password request is only valid for 24 hours.</span>
            </div>

            <div class="alert alert-success" *ngIf="success">
                <span jhiTranslate="reset.finish.messages.success"><strong>Your password has been reset.</strong> Please </span>
                <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a>.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" jhiTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>

            <div class="pb-3" *ngIf="key && !success">
                <form name="form" role="form" (ngSubmit)="finishReset()" [formGroup]="passwordForm">
                    <div class="form-group">
                        <label class="form-control-label" for="newPassword" jhiTranslate="global.form.newpassword.label">New password</label>
                        <input type="password" class="form-control" id="newPassword" name="newPassword"
                               placeholder="{{ 'global.form.newpassword.placeholder' | translate }}"
                               formControlName="newPassword" #newPassword>

                        <div *ngIf="passwordForm.get('newPassword')!.invalid && (passwordForm.get('newPassword')!.dirty || passwordForm.get('newPassword')!.touched)">
                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('newPassword')?.errors?.required"
                                   jhiTranslate="global.messages.validate.newpassword.required">
                                Your password is required.
                            </small>

                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('newPassword')?.errors?.minlength"
                                   jhiTranslate="global.messages.validate.newpassword.minlength">
                                Your password is required to be at least 4 characters.
                            </small>

                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('newPassword')?.errors?.maxlength"
                                   jhiTranslate="global.messages.validate.newpassword.maxlength">
                                Your password cannot be longer than 50 characters.
                            </small>
                        </div>

                        <jhi-password-strength-bar [passwordToCheck]="passwordForm.get('newPassword')!.value"></jhi-password-strength-bar>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label" for="confirmPassword" jhiTranslate="global.form.confirmpassword.label">New password confirmation</label>
                        <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                               placeholder="{{ 'global.form.confirmpassword.placeholder' | translate }}"
                               formControlName="confirmPassword">

                        <div *ngIf="passwordForm.get('confirmPassword')!.invalid && (passwordForm.get('confirmPassword')!.dirty || passwordForm.get('confirmPassword')!.touched)">
                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('confirmPassword')?.errors?.required"
                                   jhiTranslate="global.messages.validate.confirmpassword.required">
                                Your password confirmation is required.
                            </small>

                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('confirmPassword')?.errors?.minlength"
                                   jhiTranslate="global.messages.validate.confirmpassword.minlength">
                                Your password confirmation is required to be at least 4 characters.
                            </small>

                            <small class="form-text text-danger"
                                   *ngIf="passwordForm.get('confirmPassword')?.errors?.maxlength"
                                   jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                                Your password confirmation cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>

                    <button type="submit" class="primary" mat-stroked-button jhiTranslate="reset.finish.form.button" [disabled]="passwordForm.invalid">Reset Password</button>
                </form>
            </div>
        </div>
    </div>
</div>
