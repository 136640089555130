export class ModalDialogConfiguration {
  header: string;
  headerText: string;
  isCrossClose = true;
  isBody = true;
  body: string;
  bodyText: string;
  isFooter = true;
  footer: string;
  isYes = false;
  yesLabel = 'Yes';
  yesClass = 'primary';
  isNo = false;
  noLabel = 'No';
  noClass = 'no';
  isClose = true;
  closeLabel = 'Close';
  isCustom = false;
  customText: string;
  customLabel: string;
  customAction: string;
  buttonText: string;

  public constructor(init?: Partial<ModalDialogConfiguration>) {
    Object.assign(this, init);
  }
}
