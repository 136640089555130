import moment from 'moment';

export function dateMoment(value?: any) {
  var self: any = moment(value);

  self.__proto__ = dateMoment.prototype;

  return self;
}

dateMoment.prototype.__proto__ = moment.prototype;

dateMoment.prototype.toString = function() {
  return this.format('YYYY-MM-DD');
};

dateMoment.prototype.toJSON = function() {
  return this.format('YYYY-MM-DD');
};

dateMoment.prototype.clone = function() {
  const cloned = dateMoment(this);
  return Object.assign(cloned, { _pf: { ...cloned._pf } }); // need to clone this otherwise its the same reference as the old
};
