import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SettingsLoaderService } from '@supoui/core/services/settings.loader.service';
import { initializeApp } from '@supoui/shared/util/app-init';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { MainComponent } from './layouts/main/main.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { SidenavComponent } from './layouts/sidenav/sidenav.component';
import { AppRoutingModule } from './app-routing.module';
import { CaseRedirectorComponent } from './shared/redirector/case-redirector.component';
import { TranslationModule } from '@supoui/shared/language/translation.module';
import { AppRouteReuseStrategy } from './shared/components/route-reuse-strategy/route-reuse-strategy.component';
import { RouteReuseStrategy } from '@angular/router';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    TranslationModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [SettingsLoaderService] },
    { provide: LOCALE_ID, useValue: 'da-DK' },
    {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy}
  ],
  declarations: [
    AppComponent,
    AuthComponent,
    MainComponent,
    ErrorComponent,
    PageRibbonComponent,
    ActiveMenuDirective,
    FooterComponent,
    SidenavComponent,
    CaseRedirectorComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
