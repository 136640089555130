import { Injectable } from '@angular/core';
import moment from 'moment';
import { Adapter } from './../interfaces/adapter';
import { dateMoment } from './date-moment';
import { Language, languages } from './language';

export class Stakeholder {
  id: string;
  nationalId: string;
  nationalIdCountry: string;
  passportNumber: string;
  dateOfBirth: moment.Moment | null;
  age: string;
  gender: string;
  firstName: string;
  surname: string;
  homeCountry: string;
  homeCountryCode: string;
  mobilePhoneCountryCode: string;
  mobilePhoneCountryPrefix: string;
  mobilePhonePrefix: string;
  mobilePhone: string;
  fixedPhoneCountryCode: string;
  fixedPhoneCountryPrefix: string;
  fixedPhonePrefix: string;
  fixedPhone: string;
  timezone: number;
  gopPhoneCountryCode: string;
  gopPhoneCountryPrefix: string;
  gopPhonePrefix: string;
  gopPhone: string;
  email: string;
  remarks: string;
  dateOfDeath: Date | null;
  postalCode: string;
  address: string;
  city: string;
  region: string;
  nameInPassport: boolean;
  preferredChannel: string;
  preferredLanguage: Language;
  dateOfBirthCalculated: boolean;
  genderCalculated: boolean;
  emailStatus: string;
  company: boolean;
  faxCountryCode: string;
  faxCountryPrefix: string;
  faxPrefix: string;
  fax: string;
  gopFaxCountryCode: string;
  gopFax: string;
  gopFaxPrefix: string;
  gopFaxCountryPrefix: string;
  contactPerson: string;
  companyName: string;
  nationalIdValid: boolean;
  ssnValidated: boolean;
  stakeholderType: string;
  stakeholderTypeId: number;
  deactivated: boolean;
  lookupPerformed: boolean;
  ssnLookupPossible: boolean;
  gopRemarks: string;
  gopMethod: string;
  contactName: string;
  gopEmail: string;
  agentName: string;
  providerTypeId: number;
  longitude: number;
  latitude: number;
  supplierId: string;
  location: Location;

  public constructor(init?: Partial<Stakeholder>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class StakeholderAdapter implements Adapter<Stakeholder> {
  adapt(item: any): Stakeholder {
    return new Stakeholder({
      id: item.id,
      nationalId: item.nationalId,
      nationalIdCountry: item.nationalIdCountry,
      passportNumber: item.passportNumber,
      dateOfBirth: item.dateOfBirth === null ? null : dateMoment(item.dateOfBirth),
      age: item.age,
      gender: item.gender,
      firstName: item.firstName,
      surname: item.surname,
      homeCountry: item.homeCountry,
      homeCountryCode: item.homeCountryCode,
      mobilePhoneCountryCode: item.mobilePhoneCountryCode,
      mobilePhoneCountryPrefix: item.mobilePhoneCountryPrefix,
      mobilePhonePrefix: item.mobilePhonePrefix,
      mobilePhone: item.mobilePhone,
      fixedPhoneCountryCode: item.fixedPhoneCountryCode,
      fixedPhoneCountryPrefix: item.fixedPhoneCountryPrefix,
      fixedPhone: item.fixedPhone,
      fixedPhonePrefix: item.fixedPhonePrefix,
      gopPhoneCountryCode: item.gopPhoneCountryCode,
      gopPhoneCountryPrefix: item.gopPhoneCountryPrefix,
      gopPhone: item.gopPhone,
      gopPhonePrefix: item.gopPhonePrefix,
      email: item.email,
      remarks: item.remarks,
      dateOfDeath: item.dateOfDeath === null ? null : item.dateOfDeath,
      postalCode: item.postalCode,
      address: item.address,
      city: item.city,
      region: item.region,
      nameInPassport: item.nameInPassport,
      preferredChannel: item.preferredChannel,
      preferredLanguage: languages.find(a => a.name === item.preferredLanguage),
      dateOfBirthCalculated: item.dateOfBirthCalculated,
      genderCalculated: item.genderCalculated,
      emailStatus: item.emailStatus,
      company: item.company,
      faxCountryCode: item.faxCountryCode,
      faxCountryPrefix: item.faxCountryPrefix,
      faxPrefix: item.faxPrefix,
      fax: item.fax,
      gopFaxCountryCode: item.gopFaxCountryCode,
      gopFaxCountryPrefix: item.gopFaxCountryPrefix,
      gopFaxPrefix: item.gopFaxPrefix,
      gopFax: item.gopFax,
      contactPerson: item.contactPerson,
      companyName: item.companyName,
      nationalIdValid: item.nationalIdValid,
      stakeholderType: item.stakeholderType,
      stakeholderTypeId: item.stakeholderTypeId,
      providerTypeId: item.providerTypeId,
      deactivated: item.deactivated,
      ssnValidated: item.ssnValidated,
      lookupPerformed: item.lookupPerformed,
      ssnLookupPossible: item.ssnLookupPossible,
      gopRemarks: item.gopRemarks,
      gopMethod: item.gopMethod,
      contactName: item.contactName,
      gopEmail: item.gopEmail,
      agentName: item.agentName,
      latitude: item.latitude,
      longitude: item.longitude,
      supplierId: item.supplierId,
      location: item.location
    });
  }
}
