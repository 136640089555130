import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from '@supoui/core/auth/account.service';
import { SettingsService } from '@supoui/core/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

export class Error {
  constructor(public error: any) {}
}

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {
  private _errorMessage: string;
  private _errorStringified: any;

  show = false;

  constructor(private accountService: AccountService,
              private settingService: SettingsService,
              private translateService: TranslateService,
              private logger: NGXLogger) {}

  @Input()
  public set errorMessage(value: any) {
    if (value) {
      this.logger.debug('errorMessage', value);
    }

    this._errorMessage = '';
    if (value && typeof value === 'string') {
      this._errorMessage = value;
    }
    if (value && value instanceof Error) {
      value = value.error;
      this._errorMessage = value.error;
    }
    if (value && value instanceof HttpErrorResponse) {
      this._errorStringified = JSON.stringify(value, null, '\t');
      if (value.error && value.error.title && value.error.detail) {
        this._errorMessage = `${value.error.title}: ${value.error.detail}`;
      } else if (value.error && value.error.title && value.error.message && value.error.errorKey) {
        this._errorMessage = this.translateService.instant(value.error.errorKey);
      } else {
        this._errorMessage = value.message;
      }
    }
  }

  public get errorMessage(): any {
    return this._errorMessage;
  }

  public get errorStringified(): any {
    return this._errorStringified;
  }

  public toggle(): void {
    if (!this.settingService.isProduction() || this.isAdmin()) {
      this.show = !this.show;
    }
  }

  private isAdmin(): boolean {
    return this.accountService.getLogin() === 'admin';
  }
}

/*
entityName: "userManagement"
errorKey: "userexists"
message: "error.userexists"
params: "userManagement"
status: 400
title: "Login name already used!"
type: "https://www.jhipster.tech/problem/login-already-used"
*/
