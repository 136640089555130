<div class="modal-content">
    <div class="modal-header">
        <h3>{{configuration.header ? (configuration.header | translate) : configuration.headerText}}</h3>
        <div class="modal-close" *ngIf="configuration.isCrossClose">
            <img class="cross-close" src="/content/icons/Close-Black.svg" (click)="activeModal.dismiss('closed')">
        </div>
    </div>
    <div class="modal-body" *ngIf="configuration.isBody">
        <div class="modal-body-content">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="modal-footer" *ngIf="configuration.isFooter">
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
            <button mat-stroked-button class="secondary" (click)="navigateTo(configuration.customAction)" *ngIf="configuration.isCustom">{{configuration.customLabel | translate}}</button>
            <button mat-stroked-button [class]="configuration.noClass" (click)="close(false)" *ngIf="configuration.isNo">{{configuration.noLabel | translate}}</button>
            <button mat-stroked-button [class]="configuration.yesClass" (click)="close(true)" *ngIf="configuration.isYes">{{configuration.yesLabel | translate}}</button>
            <button mat-stroked-button (click)="activeModal.dismiss('closed')" *ngIf="configuration.isClose">{{configuration.closeLabel | translate}}</button>
        </div>
    </div>
</div>
