import { NgModule } from '@angular/core';
import { SharedLibsModule } from './shared-libs.module';
import { TranslateDirective } from '@supoui/shared/language/translate.directive';
import { FindLanguageFromKeyPipe, FindLanguageFlagFromKeyPipe } from './language/find-language-from-key.pipe';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { LoginModalComponent } from './login/login.component';
import { RequestAccountModalComponent } from './login/request-account-modal/request-account-modal.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { MaterialModule } from './material.module';
import { InputPatternDirective } from './directives/input-pattern.directive';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { InputComponent } from './components/input/input.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DropDownInputComponent } from './components/drop-down-input/drop-down-input.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { InputCalendarComponent } from './components/input-calendar/input-calendar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HeaderComponent } from '../layouts/header/header.component';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { FormControlErrorMessageComponent } from './components/form-control-error-message/form-control-error-message.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { InputDatePickerComponent } from './components/input-date-picker/input-date-picker.component';
import { InputLocationComponent } from './components/input-location/input-location.component';
import { MapComponent } from './components/map/map.component';
import { ActionBarComponent } from '../layouts/action-bar/action-bar.component';
import { InputAutoCompleteComponent } from './components/input-autocomplete/input-autocomplete.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { OpenInvoicePdfComponent } from './components/open-invoice-pdf/open-invoice-pdf.component';
import { TableauComponent } from './components/tableau/tableau.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { TermsOfUseModalComponent } from './components/terms-of-use-modal/terms-of-use-modal.component';
import { NeedHelpLoggingInModalComponent } from './components/need-help-logging-in-modal/need-help-logging-in-modal.component';
import { EnvironmentRibbonComponent } from './components/environment-ribbon/environment-ribbon.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MomentPipe } from './pipes/moment-pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { InputToggleComponent } from './components/input-toggle/input-toggle.component';
import { CreditCardFormatterDirective } from './directives/credit-card-formatter.directive';
import { VideoStreamModalComponent } from './components/video-stream-modal/video-stream-modal.component';
import { InputCountryComponent } from './components/input-country/input-country.component';
import { UploadComponent } from './components/upload/upload.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { TaskWebModalComponent } from './components/task-web-modal/task-web-modal.component';
import { TaskWebDetailsPanelComponent } from './components/task-web-modal/task-web-content-panel/task-web-details-panel.component';
import { TaskWebMedicalReportPanelComponent } from './components/task-web-modal/task-web-medical-report-panel/task-web-medical-report-panel.component';
import { TaskWebPropertiesComponent } from './components/task-web-modal/task-web-properties/task-web-properties.component';
import { TaskWebPropertyComponent } from './components/task-web-modal/task-web-property/task-web-property.component';
import { ContextHelpModalComponent } from './components/context-help-modal/context-help-modal.component';
import { DecimalPipe } from '@angular/common';
import { TabSelected } from './components/input-autocomplete/tab-selected.directive';
import { SendFormModalComponent } from './components/send-form-modal/send-form-modal.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { NewInvoiceComponent } from './components/invoice/new-invoice/new-invoice.component';
import { NewInvoiceSuccessModalComponent } from './components/invoice/new-invoice-success-modal/new-invoice-success-modal.component';
import { SupplierInvoiceItemComponent } from './components/invoice/supplier-invoice-item/supplier-invoice-item.component';
import { BatchBillingSuccessModalComponent } from './components/invoice/batch-billing-success-modal/batch-billing-success-modal.component';
import { TrimFormControlValueDirective } from './directives/trim-form-control-value.directive';
import { ReloadFooterComponent } from './components/reload-footer/reload-footer.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { ImageCacheDirective } from './directives/image-cache.directive';

@NgModule({
  imports: [MaterialModule, SharedLibsModule, VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule],
  declarations: [
    TranslateDirective,
    FindLanguageFromKeyPipe,
    FindLanguageFlagFromKeyPipe,
    AlertComponent,
    AlertErrorComponent,
    LoginModalComponent,
    RequestAccountModalComponent,
    HasAnyAuthorityDirective,
    InputPatternDirective,
    // SOS components
    ErrorMessageComponent,
    InputComponent,
    InputCalendarComponent,
    InputAutoCompleteComponent,
    TextBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    PaginationComponent,
    DropDownInputComponent,
    ModalDialogComponent,
    ConfirmModalComponent,
    SpinnerComponent,
    HeaderComponent,
    NavbarComponent,
    ActionBarComponent,
    FormErrorMessageComponent,
    FormControlErrorMessageComponent,
    InputDateComponent,
    InputDateTimeComponent,
    InputDatePickerComponent,
    InputLocationComponent,
    MapComponent,
    SafeHtmlPipe,
    RouteTransformerDirective,
    OpenInvoicePdfComponent,
    TableauComponent,
    TermsOfUseComponent,
    TermsOfUseModalComponent,
    NeedHelpLoggingInModalComponent,
    EnvironmentRibbonComponent,
    MomentPipe,
    YesNoPipe,
    InputToggleComponent,
    CreditCardFormatterDirective,
    VideoStreamModalComponent,
    InputCountryComponent,
    UploadComponent,
    NumberFormatPipe,
    TaskWebModalComponent,
    TaskWebDetailsPanelComponent,
    TaskWebMedicalReportPanelComponent,
    TaskWebPropertyComponent,
    TaskWebPropertiesComponent,
    ContextHelpModalComponent,
    SendFormModalComponent,
    TabSelected,
    InputTrimDirective,
    NewInvoiceComponent,
    NewInvoiceSuccessModalComponent,
    BatchBillingSuccessModalComponent,
    SupplierInvoiceItemComponent,
    TrimFormControlValueDirective,
    InfoBarComponent,
    ReloadFooterComponent,
    ImageCacheDirective
  ],
  exports: [
    MaterialModule,
    SharedLibsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    TranslateDirective,
    FindLanguageFromKeyPipe,
    FindLanguageFlagFromKeyPipe,
    AlertComponent,
    AlertErrorComponent,
    LoginModalComponent,
    RequestAccountModalComponent,
    HasAnyAuthorityDirective,
    InputPatternDirective,
    // SOS components
    ErrorMessageComponent,
    InputComponent,
    InputCalendarComponent,
    InputAutoCompleteComponent,
    TextBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    PaginationComponent,
    DropDownInputComponent,
    ModalDialogComponent,
    ConfirmModalComponent,
    SpinnerComponent,
    HeaderComponent,
    NavbarComponent,
    ActionBarComponent,
    FormErrorMessageComponent,
    FormControlErrorMessageComponent,
    InputDateComponent,
    InputDateTimeComponent,
    InputDatePickerComponent,
    InputLocationComponent,
    MapComponent,
    SafeHtmlPipe,
    RouteTransformerDirective,
    OpenInvoicePdfComponent,
    TableauComponent,
    TermsOfUseComponent,
    TermsOfUseModalComponent,
    NeedHelpLoggingInModalComponent,
    EnvironmentRibbonComponent,
    MomentPipe,
    YesNoPipe,
    InputToggleComponent,
    CreditCardFormatterDirective,
    VideoStreamModalComponent,
    InputCountryComponent,
    UploadComponent,
    NumberFormatPipe,
    TaskWebModalComponent,
    TaskWebDetailsPanelComponent,
    TaskWebPropertyComponent,
    TaskWebPropertiesComponent,
    ContextHelpModalComponent,
    SendFormModalComponent,
    InputTrimDirective,
    NewInvoiceComponent,
    NewInvoiceSuccessModalComponent,
    BatchBillingSuccessModalComponent,
    SupplierInvoiceItemComponent,
    TrimFormControlValueDirective,
    InfoBarComponent,
    ReloadFooterComponent,
    ImageCacheDirective
  ],
  providers: [FindLanguageFromKeyPipe, FindLanguageFlagFromKeyPipe, DecimalPipe]
})
export class SharedModule {}
