import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '@supoui/core/login/login.service';
import { LoginModalService } from '@supoui/core/login/login-modal.service';
import { StateStorageService } from '@supoui/core/auth/state-storage.service';
import { SettingsService } from '@supoui/core/services/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private loginModalService: LoginModalService,
    private stateStorageService: StateStorageService,
    private settingService: SettingsService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (err: HttpErrorResponse) => {
        if (err.status === 500) {
          if (this.settingService.isProduction()) {
            localStorage.setItem(
              'service-session-error',
              'Problem contacting servers. - please try again later. If it doesn`t work, please contact us at supplierportal@sos.eu'
            );
          } else {
            localStorage.setItem('service-session-error', 'Problem contacting servers. ' + err?.url + ' - ' + err?.message);
          }
        }
        if (err.status === 401 && err.url && !err.url.includes('api/account')) {
          if (err.url.includes(this.loginService.logoutUrl())) {
            localStorage.setItem('service-session-error', this.translateService.instant('Your session has timed out. Please login again.'));
            this.loginService.logoutInClient();
            return;
          } else if (err.url.includes('services')) {
            if (this.settingService.isProduction()) {
              localStorage.setItem(
                'service-session-error',
                this.translateService.instant(
                  "Your session has timed out or the system is currently unavailable - please try again. If it doesn't work, please contact us at supplierportal@sos.eu"
                )
              );
            } else {
              const services: string[] = [];
              if (err.url.includes('services/casemanagement')) {
                services.push('case management');
              }
              if (err.url.includes('services/caseeconomy')) {
                services.push('case economy');
              }
              if (err.url.includes('services/onepoint')) {
                services.push('one point');
              }
              localStorage.setItem(
                'service-session-error',
                this.translateService.instant(
                  "Your session has timed out or the system is currently unavailable - please try again. If it doesn't work, please contact us at supplierportal@sos.eu"
                )
              );
            }
          }
          if (!this.router.routerState.snapshot.url.includes('/auth/sign-in')) {
            this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
          }
          this.loginService.logout();
          this.router.navigate(['/auth/sign-in']);
        }
      })
    );
  }
}
