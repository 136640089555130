import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogConfiguration } from '@supoui/shared/model/modal/modal-dialog-configuration';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
  @Input() configuration: ModalDialogConfiguration;

  @Output() closeModalEvent = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {}

  close(close: boolean): void {
    this.closeModalEvent.emit(close);
  }

  navigateTo(action: string): void {
    this.router.navigate([action]);
    this.activeModal.dismiss('closed');
  }
}
