import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { PhoneNumber } from './phone-number';
import { Person } from './person';
import { Company } from './company';
import { Address } from './address';
import { StakeholderTypeEnum } from '@supoui/shared/model/enumerations/stakeholder-type.enum';

export class CaseStakeholder {
  id: string;
  firstName: string;
  surname: string;
  age: number;
  gender: string;
  dateOfBirth: string;
  city: string;
  nationalId: string;
  nationalIdValid: boolean;
  nationalIdCountry: string;

  caseId: number;
  stakeholderType: StakeholderTypeEnum;
  stakeholderTypeId: number;
  stakeholderId: number;
  person: Person;
  company: Company;
  companyName: string;
  address: Address;
  isCompany: boolean;
  phoneNumbers: PhoneNumber[];
  email: string;
  primaryContact: boolean;
  legalGuardian: boolean;
  primaryContactChangeable: boolean;
  legalGuardianChangeable: boolean;
  coTraveller: boolean;
  enduserIsPolicyholder: boolean;
  enduserIsReporter: boolean;
  reporter: boolean;
  policyholderIsReporter: boolean;
  similarStakeholders: string[];
  properties: string[];
  stakeholderTypeShowName: string;
  imageName: string;
  sortId: number;
  deactivated: boolean;
  timezone: number;
  homeCountryCode: string;
  homeCountryName: string;
  nationalCountryCode: string;
  suppliersReference: string;
  providerReference: string;
  providerId: string;

  /*
    {
      "id": 1228,
      "nationalIdCountry": "SE",
      "nationalId": "850606-1244",
      "dateOfBirth": "1985-06-06",
      "age": 36,
      "gender": "F",
      "firstName": "Michelle",
      "surname": "Karlsson",
      "phoneNumbers": [
        {
          "id": 2459,
          "type": "MOBILE",
          "prefix": "+45",
          "number": "51236169",
          "countryCode": "DK"
        }
      ],
      "email": "cdm@sos.eu",
      "isCompany": false,
      "nationalIdValid": true,
      "stakeholderType": "END_USER",
      "properties": [
        "REPORTER",
        "LEGAL_GUARDIAN"
      ]
    }
*/

  public constructor(init?: Partial<CaseStakeholder>) {
    Object.assign(this, init);

    if (!init || !init.address) {
      this.address = new Address();
    }
  }

  hasRole(role: string): boolean {
    return this.stakeholderType === role || (this.properties != null && this.properties.includes(role));
  }
}

@Injectable({
  providedIn: 'root'
})
export class CaseStakeholderAdapter implements Adapter<CaseStakeholder> {
  adapt(item: any): CaseStakeholder {
    return new CaseStakeholder({
      id: item.id,
      caseId: item.caseId,
      stakeholderType: item.stakeholderType,
      stakeholderTypeId: item.stakeholderTypeId,
      company: item.company !== null ? item.company : null,
      person: item.person !== null ? item.person : null,
      address: item.address,
      phoneNumbers: item.phoneNumbers,
      isCompany: item.company !== null,
      companyName: item.companyName,
      email: item.company !== null ? item.company.email : item.person.email,
      stakeholderId: item.stakeholderId,
      primaryContact: item.primaryContact,
      legalGuardian: item.legalGuardian,
      primaryContactChangeable: item.primaryContactChangeable,
      legalGuardianChangeable: item.legalGuardianChangeable,
      coTraveller: item.coTraveller,
      enduserIsPolicyholder: item.enduserIsPolicyholder,
      enduserIsReporter: item.enduserIsReporter,
      reporter: item.reporter,
      deactivated: item.deactivated,
      policyholderIsReporter: item.policyholderIsReporter,
      similarStakeholders: item.similarStakeholders,
      properties: item.properties,
      suppliersReference: item.suppliersReference,
      providerReference: item.providerReference,
      providerId: item.providerId
    });
  }
}
