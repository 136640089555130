import { Component, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DisplayPageType, PageType } from '@supoui/shared/components/info-bar/info-bar.component';

@Component({
  selector: 'jhi-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  messageActive: boolean = true;
  frontpage: PageType = PageType.FRONT_PAGE;
  constructor() {}

  ngOnInit(): void {}
}
