import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { IRole } from './role.model';
import { createRequestOption, SearchWithPagination } from '@supoui/shared/util/request-util';
import { SERVER_API_URL } from '../../app.constants';

@Injectable({ providedIn: 'root' })
export class RoleService {
  public resourceUrl = 'services/onesosuaa/api/supplier-roles';
  public resourceQueryUrl = SERVER_API_URL + 'services/onesosuaa/api/supplier-roles-by-query';

  constructor(private httpService: HttpService, private http: HttpClient) {}

  create(role: IRole): Observable<IRole> {
    return this.httpService.post<IRole>(this.resourceUrl, role);
  }

  update(role: IRole): Observable<IRole> {
    return this.httpService.put<IRole>(this.resourceUrl, role);
  }

  delete(roleId: string): Observable<{}> {
    return this.httpService.delete(`${this.resourceUrl}/${roleId}`);
  }

  roles(): Observable<IRole[]> {
    return this.httpService.get<IRole[]>(this.resourceUrl);
  }

  customerRoles(): Observable<IRole[]> {
    return this.httpService.get<IRole[]>(this.resourceUrl);
  }

  supplierRoles(): Observable<IRole[]> {
    return this.httpService.get<IRole[]>(this.resourceUrl);
  }

  query(req?: SearchWithPagination): Observable<HttpResponse<IRole[]>> {
    const options = createRequestOption(req);
    return this.http.get<IRole[]>(this.resourceQueryUrl, { params: options, observe: 'response' });
  }
}
