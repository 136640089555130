import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { HttpService } from '@supoui/core/services/http.service';
import { SessionStorageService } from 'ngx-webstorage';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'jhi-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss']
})
export class InfoBarComponent implements OnInit {
  @Input() pageType: PageType = PageType.FRONT_PAGE;
  aimMessageFrontPage: AimMessage = {};
  aimMessageLastPage: AimMessage = {};
  applicationName = 'onesos-supo';

  constructor(private httpService: HttpService, private sessionStorage: SessionStorageService, private router: Router) {}

  ngOnInit(): void {
    this.getAimText('en', PageType.FRONT_PAGE);
    this.getAimText('en', PageType.LAST_PAGE);
  }

  getAimText(alpha2code: string = 'en', pageType: PageType): void {
    const aimUrl = `services/aim/public/message/${this.applicationName}/${alpha2code}/${pageType}/`;
    this.httpService
      .get<AimMessage>(aimUrl)
      .pipe(take(1))
      .subscribe({
        next: (res: AimMessage) => {
          if (pageType === PageType.FRONT_PAGE && !!res) {
            if (this.areMessageOnAimUpdated(PageType.FRONT_PAGE, res)) {
              this.sessionStorage.store(DisplayPageType.DISPLAY_FRONT_PAGE, true);
            }
            this.saveHex(PageType.FRONT_PAGE, this.calculateHex(res));
            this.aimMessageFrontPage = res;
          } else if (pageType === PageType.LAST_PAGE && !!res) {
            if (this.areMessageOnAimUpdated(PageType.LAST_PAGE, res)) {
              this.sessionStorage.store(DisplayPageType.DISPLAY_LAST_PAGE, true);
            }
            this.saveHex(PageType.LAST_PAGE, this.calculateHex(res));
            this.aimMessageLastPage = res;
          }
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }

  messageActive(): undefined | boolean {
    if (this.sessionStorage.retrieve('AIM_MESSAGE') === true) {
      if (this.pageType === PageType.FRONT_PAGE) {
        const frontPage =
          this.sessionStorage.retrieve(DisplayPageType.DISPLAY_FRONT_PAGE) !== null
            ? this.sessionStorage.retrieve(DisplayPageType.DISPLAY_FRONT_PAGE)
            : true;
        return this.aimMessageFrontPage?.textActive && frontPage;
      } else {
        const lastPage =
          this.sessionStorage.retrieve(DisplayPageType.DISPLAY_LAST_PAGE) !== null
            ? this.sessionStorage.retrieve(DisplayPageType.DISPLAY_LAST_PAGE)
            : true;
        return this.aimMessageLastPage?.textActive && lastPage;
      }
    }
    return false;
  }

  setDisplayInfoBox() {
    if (this.pageType === PageType.FRONT_PAGE) {
      this.sessionStorage.store(DisplayPageType.DISPLAY_FRONT_PAGE, false);
    } else {
      this.sessionStorage.store(DisplayPageType.DISPLAY_LAST_PAGE, false);
    }
  }

  private calculateHex(res: AimMessage): string {
    const string: string = JSON.stringify(res?.message);
    let hex = '';
    for (let i = 0; i < string.length; i++) {
      hex += string.charCodeAt(i).toString(16);
    }
    return hex;
  }

  private saveHex(pageType: PageType, hex: string): void {
    this.sessionStorage.store(pageType, hex);
  }

  private areMessageOnAimUpdated(pageType: PageType, res: AimMessage) {
    return this.calculateHex(res) !== this.sessionStorage.retrieve(pageType);
  }
}

export enum PageType {
  FRONT_PAGE = 'frontpage',
  LAST_PAGE = 'lastpage'
}

export enum DisplayPageType {
  DISPLAY_FRONT_PAGE = 'displayInfoBoxFrontPage',
  DISPLAY_LAST_PAGE = 'displayInfoBoxLastPage'
}

export interface AimMessage {
  id?: number;
  application?: {
    id: number;
    name: string;
    applicationActive: boolean;
  };
  language?: {
    id: number;
    alpha2code: string;
  };
  message?: string;
  startDate?: string;
  endDate?: string;
  textActive?: boolean;
  messageType?: {
    id: number;
    name: string;
    description: string;
  };
}
