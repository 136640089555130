import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@supoui/core/services/http.service';
import { CustomerNameHolder, CustomerNameAdapter, CustomerProfiles, CustomerProfilesAdapter } from '../../shared/model/customerProfiles';
import { SERVER_API_URL } from '../../app.constants';
import { PlannedTravelAdapter, PlannedTravel } from '@supoui/shared/model/planned-travel';
import { Case, CaseAdapter } from '@supoui/shared/model/case';
import { CaseStakeholder, CaseStakeholderAdapter } from '@supoui/shared/model/caseStakeholder';
import { ICaseSupplierNote } from '@supoui/shared/model/case-supplier-note';
import { ICaseDocument } from '@supoui/shared/model/case-document';
import { ICaseRegularMedicalAssessment, ICaseMedicalHistory, MedicalHistoryType } from '@supoui/shared/model/case-medical';
import { Dictionary } from '@supoui/shared/model/dictionary';
import { IServiceOrder } from '@supoui/shared/model/service-order';
import { ICoverageType } from '@supoui/shared/model/coverage-type';
import { ICaseSuppliersReference } from '@supoui/shared/model/case-suppliers-reference';
import { ICaseNote } from '@supoui/shared/model/case-note';
import { IApplicationCustomerProfile } from '@supoui/shared/model/application-customer-profile';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  constructor(
    private httpService: HttpService,
    private customerNameAdapter: CustomerNameAdapter,
    private customerProfilesAdapter: CustomerProfilesAdapter,
    private plannedTravelAdapter: PlannedTravelAdapter,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private caseAdapter: CaseAdapter
  ) {}

  public getCase(id: string): Observable<Case> {
    return this.httpService.get(`services/casemanagement/api/cases/${id}`).pipe(map(item => this.caseAdapter.adapt(item)));
  }

  public getStakeholderForCase(caseId: string, type: string): Observable<CaseStakeholder> {
    return this.httpService
      .get(`services/casemanagement/api/cases/${caseId}/stakeholders/type/${type}`)
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getCaseDocuments(caseId: string): Observable<ICaseDocument[]> {
    return this.httpService
      .get<ICaseDocument[]>(`services/casemanagement/api/documents/case/${caseId}`)
      .pipe(map((data: ICaseDocument[]) => data.filter(item => !item.unhandled)));
  }

  public getCaseRegularMedicalAssessment(caseId: string): Observable<ICaseRegularMedicalAssessment[]> {
    return this.httpService.get<ICaseRegularMedicalAssessment[]>(`services/casemanagement/api/medical-assessment/case/${caseId}`);
  }

  public getCaseMedicalHistory(caseId: string): Observable<ICaseMedicalHistory[]> {
    return this.httpService
      .get<ICaseMedicalHistory[]>(`services/casemanagement/api/medical-history/case/${caseId}`)
      .pipe(map((data: ICaseMedicalHistory[]) => data.filter(item => item.medicalHistoryType !== MedicalHistoryType.medicalComment)));
  }

  public getServiceOrders(caseId: string): Observable<IServiceOrder[]> {
    return this.httpService.get<IServiceOrder[]>(`services/casemanagement/api/service-orders/by-case/${caseId}`);
  }

  public getCustomerProfile(profileId: string): Observable<CustomerNameHolder> {
    return this.httpService
      .get<CustomerNameHolder>(`services/onepoint/api/profile/${profileId}`)
      .pipe(map(item => this.customerNameAdapter.adapt(item)));
  }

  public getPlannedTravel(caseId: string): Observable<PlannedTravel> {
    return this.httpService
      .get<any>(`services/casemanagement/api/travelplans/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.plannedTravelAdapter.adapt(item))[0]));
  }

  public getSupplierNotes(caseId: string): Observable<ICaseSupplierNote[]> {
    return this.httpService.get<ICaseSupplierNote[]>(`services/casemanagement/api/notes/by-entity-id/${caseId}`);
  }

  public addSupplierNote(supplierNote: Partial<ICaseSupplierNote>): Observable<ICaseNote> {
    return this.httpService.post<ICaseNote>('services/casemanagement/api/notes', supplierNote);
  }

  public getSuppliersReferences(caseId: string): Observable<ICaseSuppliersReference[]> {
    return this.httpService.get<ICaseSuppliersReference[]>(`services/casemanagement/api/stakeholders/suppliers-reference/${caseId}`);
  }

  public setSuppliersReference(suppliersReference: Partial<ICaseSuppliersReference>): Observable<ICaseSuppliersReference[]> {
    return this.httpService.put<ICaseSuppliersReference[]>(
      `services/casemanagement/api/stakeholders/suppliers-reference`,
      suppliersReference
    );
  }

  public getBrandImagesUrlForProfile(profile: string): string {
    return SERVER_API_URL + `api/profile/${profile}/brand-icon`;
  }

  public getBrandImagesUrlForBrand(brand: string): string {
    return SERVER_API_URL + `services/onepoint/api/brand/${brand}/icon`;
  }

  public getProfileProducts(profileId: number): Observable<Dictionary[]> {
    return this.httpService
      .get<Dictionary[]>(`services/onepoint/api/products/profile/${profileId}?activeOnly=true`)
      .pipe(map((data: any[]) => data?.map(item => new Dictionary(item.id, item.name))));
  }

  public getCaseStakeholdersOnCase(caseId: string): Observable<CaseStakeholder[]> {
    return this.httpService
      .get<any[]>(`services/casemanagement/api/cases/${caseId}/stakeholders`)
      .pipe(map((data: CaseStakeholder[]) => data.map(item => this.caseStakeholderAdapter.adapt(item))));
  }

  public getValidCaseCoverages(caseId: string): Observable<ICoverageType[]> {
    return this.httpService.get<ICoverageType[]>(`services/casemanagement/api/service-orders/case-coverages/${caseId}`);
  }

  public getCustomerTravelProfiles(): Observable<IApplicationCustomerProfile[]> {
    return this.httpService.get<IApplicationCustomerProfile[]>(`services/onepoint/api/profile/customer-travel-profiles/`);
  }

  public requestGop(requestGop: any): Observable<any> {
    return this.httpService.post<any>('services/casemanagement/api/task/create-gop-request', requestGop);
  }
}
