import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Location } from './location';

export class PlannedTravel {
  id: string;
  seccaCaseId: string;
  destinationLocation: Location;
  departureDate: Date | null;
  arrivalDate: Date | null;
  purposesOfTravel: string[];
  meansOfTransportation: string;
  accommodation: string;
  coTravellers: string;
  departureAndArrival: (Date | null)[] | null;
  durationDays: string;
  durationWeeks: string;
  travelAgency: string;

  public constructor(init?: Partial<PlannedTravel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlannedTravelAdapter implements Adapter<PlannedTravel> {
  adapt(item: any): PlannedTravel {
    let itemDestinationLocation = null;
    if (!item.destinationLocation) {
      itemDestinationLocation = new Location();
    } else {
      itemDestinationLocation = new Location({
        id: item.destinationLocation.id,
        freeText: item.destinationLocation.freeText,
        streetNumber: item.destinationLocation.streetNumber,
        streetName: item.destinationLocation.streetName,
        locality: item.destinationLocation.locality,
        administrativeAreaLevel1: item.destinationLocation.administrativeAreaLevel1,
        administrativeAreaLevel2: item.destinationLocation.administrativeAreaLevel2,
        country: item.destinationLocation.country,
        postalCode: item.destinationLocation.postalCode,
        latitude: item.destinationLocation.latitude,
        longitude: item.destinationLocation.longitude
      });
    }

    const departureDate = !item.departureDate ? null : new Date(item.departureDate);
    const arrivalDate = !item.arrivalDate ? null : new Date(item.arrivalDate);

    return new PlannedTravel({
      id: item.id,
      seccaCaseId: item.seccaCaseId,
      destinationLocation: itemDestinationLocation,
      departureDate,
      arrivalDate,
      departureAndArrival: [departureDate, arrivalDate],
      purposesOfTravel: item.purposesOfTravel ? item.purposesOfTravel : [],
      meansOfTransportation: item.meansOfTransportation,
      accommodation: item.accommodation,
      coTravellers: item.coTravellers,
      durationDays: item.durationDays,
      durationWeeks: item.durationWeeks,
      travelAgency: item.travelAgency
    });
  }
}
