import { Location } from './location';
import { PhoneNumber } from './phone-number';
import {IErpCreditor} from "@supoui/shared/model/erp-creditor";
import { SupplierProfilePartner } from './supplier-profile-partner';

export class SupplierProfile {
  id: number;
  type: number;
  supplierProfileId: number;
  supplierProfileCode: string;
  supplierProfileName: string;

  partnerSupplierProfileId: number;
  partnerSupplierProfileName: string;

  networkManagerId: string;
  networkManagerFirstname: string;
  networkManagerSurname: string;
  networkManagerEmail: string;
  networkManagerPhone: string;

  providerId: string;
  startDate: Date;
  endDate: Date;

  selected: boolean;

  supplierContactInfo?: SupplierContactInfo;

  primaryErpCreditor: IErpCreditor;
  secondaryErpCreditors: IErpCreditor[];

  supplierProfilePartners: SupplierProfilePartner[];

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.supplierProfileId = data.supplierProfileId;
    this.supplierProfileCode = data.supplierProfileCode;
    this.supplierProfileName = data.supplierProfileName;

    this.partnerSupplierProfileId = data.partnerSupplierProfileId;
    this.partnerSupplierProfileName = data.partnerSupplierProfileName;

    this.networkManagerId = data.networkManagerId;
    this.networkManagerFirstname = data.networkManagerFirstname;
    this.networkManagerSurname = data.networkManagerSurname;
    this.networkManagerEmail = data.networkManagerEmail;
    this.networkManagerPhone = data.networkManagerPhone;

    this.providerId = data.providerId;
    this.startDate = data.startDate;
    this.endDate = data.endDate;

    this.selected = false;

    this.supplierContactInfo = data.supplierContactInfo;

    this.primaryErpCreditor = data.primaryErpCreditor;
    this.secondaryErpCreditors = data.secondaryErpCreditors;
    this.supplierProfilePartners = data.supplierProfilePartners;
  }

  getErpCreditors(): IErpCreditor[] {
    const erpCreditors = [];
    if (!!this.primaryErpCreditor) {
      erpCreditors.push(this.primaryErpCreditor);
    }
    if (!!this.secondaryErpCreditors) {
      erpCreditors.push(...this.secondaryErpCreditors.filter(creditor => !!creditor));
    }
    return erpCreditors;
  }
}

export interface SupplierContactInfo {
  email?: string;
  phoneNumber?: Partial<PhoneNumber>;
  location?: Location;
}

/*
creditorCurrency: "EUR"
creditorName: "Medicus Clinic Ozel Saglik Hiz"
creditorNumber: "191512"
endDate: null
id: 1005
networkManagerEmail: "Yasir.Ahmed@sos.eu"
networkManagerFirstname: "Yasir"
networkManagerId: "sosinyah"
networkManagerPhone: "+4529743527"
networkManagerSurname: "Ahmed"
partnerSupplierProfileId: 1
providerId: "2249"
startDate: "2021-01-01"
supplierProfileCode: "SP11001"
supplierProfileId: 5
supplierProfileName: "Medicus Clinic"
type: null
user: null
 */
