import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '@supoui/core/services/base.service';
import { SettingsService } from '@supoui/core/services/settings.service';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import moment from 'moment';

export const regexISODate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;
export const regexISOLocalDate = /^(\d{4})-(\d{2})-(\d{2})$/;

export const convertJSONDate = (value: any): any => {
  if (typeof value === 'string') {
    const result = regexISODate.exec(value);
    if (result) {
      return moment.utc(value).toDate();
    } else {
      const result = regexISOLocalDate.exec(value);
      if (result) {
        return moment.utc(value, 'YYYY-MM-DD');
      }
    }
  }
  return value;
};

export const convertJSONDates = (input: any): any => {
  // Ignore things that aren't objects.
  if (!input || typeof input !== 'object') {
    return convertJSONDate(input);
  }
  Object.keys(input).map(key => {
    const value = input[key];
    // Check for string properties which look like dates.
    if (typeof value === 'string') {
      input[key] = convertJSONDate(value);
    } else if (typeof value === 'object') {
      convertJSONDates(value);
    }
  });
  return input;
};

@Injectable({
  providedIn: 'root'
})
export class HttpService extends BaseService {
  private static readonly defaultOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  private version: string;

  constructor(private http: HttpClient, protected settingService: SettingsService, private logger: NGXLogger) {
    super(settingService);
    this.version = environment.appVersion;
    if (!this.version.toLowerCase().startsWith('v')) {
      this.version = 'v' + this.version;
    }
//    this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
  }

  public get<T>(url: string, options?: any): Observable<T> {
    let normalOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    if (!url.endsWith('/')) {
      url += url.includes('?') ? `&ts=${this.version}` : `?ts=${this.version}`;
    }
    return this.http.get<T>(`${this.baseURL}${url}`, Object.assign(normalOptions, options)).pipe(
      map(json => convertJSONDates(json)),
      catchError(error => this.handleError(error))
    );
  }

  public getExternal<T>(url: string, options?: any): Observable<T> {
    let normalOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this.http.get<T>(`${url}`, Object.assign(normalOptions, options)).pipe(
      map(json => convertJSONDates(json)),
      catchError(error => this.handleError(error))
    );
  }

  public post<T = void>(path: string, data: string | object): Observable<T> {
    return this.genericUpdate<T>('post', path, data);
  }

  public put<T = void>(path: string, data: string | object): Observable<T> {
    return this.genericUpdate<T>('put', path, data);
  }

  public patch<T = void>(path: string, data: string | object): Observable<T> {
    return this.genericUpdate<T>('patch', path, data);
  }

  public delete(params: string): Observable<Object> {
    return this.http.delete(`${this.baseURL}${params}`);
  }

  public genericUpdate<T>(method: 'post' | 'put' | 'patch', path: string, data: string | object): Observable<T> {
    return this.http[method]<T>(`${this.baseURL}${path}`, data, HttpService.defaultOptions).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public getDocument(path: string, id: string, overrideMimeType?: string): Observable<Blob> {
    return this.http.get(`${this.baseURL}${path}${id}`, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      map((res: any) => {
        let mimeType = 'application/pdf';
        if (res.headers.get('content-type')) {
          mimeType = res.headers.get('content-type');
        }
        return new Blob([res.body], {
          type: overrideMimeType ? overrideMimeType : mimeType
        });
      })
    );
  }

  private handleError(error: any): Observable<never> {
    if (error?.status != '404') {
      this.logger.debug('handleError', error);
    }
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(error);
  }
}
