import { Dictionary } from './dictionary';

export class Language extends Dictionary {}

export const languages: Language[] = [
  new Language('DK', 'Danish'),
  new Language('SE', 'Swedish'),
  new Language('NO', 'Norwegian'),
  new Language('FI', 'Finnish'),
  new Language('EN', 'English'),
  new Language('IS', 'Icelandic'),
  new Language('FO', 'Faroese'),
  new Language('RU', 'Russian')
];
