import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalDialogConfiguration } from '@supoui/shared/model/modal/modal-dialog-configuration';
import { AbstractFormGroupComponent } from '@supoui/shared/components/abstract-form-group.component';
import { FindLanguageFlagFromKeyPipe, FindLanguageFromKeyPipe } from '@supoui/shared/language/find-language-from-key.pipe';
import { UserService } from '@supoui/core/user/user.service';
import { IUser } from '@supoui/core/user/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LANGUAGES } from '@supoui/core/language/language.constants';

@Component({
  selector: 'app-request-account-modal',
  templateUrl: './request-account-modal.component.html',
  styleUrls: ['./request-account-modal.component.scss']
})
export class RequestAccountModalComponent extends AbstractFormGroupComponent implements OnInit {
  modalDialogConfiguration: ModalDialogConfiguration;

  languages: any[] = [];

  requestAccountCompleted = false;

  constructor(
    private userService: UserService,
    private findLanguageFromKeyPipe: FindLanguageFromKeyPipe,
    private findLanguageFlagFromKeyPipe: FindLanguageFlagFromKeyPipe,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal
  ) {
    super();
    LANGUAGES.map(language => this.languages.push({ value: language, label: findLanguageFromKeyPipe.transform(language) }));
  }

  ngOnInit(): void {
    this.modalDialogConfiguration = new ModalDialogConfiguration({
      header: 'Request account',
      buttonText: 'Request'
    });

    this.fg = this.fb.group({
      title: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      email: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email]],
      mobilePhone: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(15)]]
      //      language: [null, [Validators.required]]
    });
  }

  save(): void {
    const values = this.fg.value;
    const newUser: IUser = { ...values };
    newUser.login = 'REQUEST_ACCOUNT';
    this.handleObservable(this.userService.register(newUser));
  }

  protected onUpdated(response: any): void {
    this.requestAccountCompleted = true;
  }
}
