import {
  faUser,
  faSort,
  faSortUp,
  faSortDown,
  faSync,
  faEye,
  faBan,
  faTimes,
  faArrowLeft,
  faSave,
  faPlus,
  faPencilAlt,
  faBars,
  faThList,
  faUserPlus,
  faRoad,
  faTachometerAlt,
  faHeart,
  faList,
  faBell,
  faBook,
  faHdd,
  faFlag,
  faWrench,
  faLock,
  faCloud,
  faSignOutAlt,
  faSignInAlt,
  faCalendarAlt,
  faSearch,
  faTrashAlt,
  faAsterisk,
  faTasks,
  faHome,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faSpinner,
  faBolt,
  faPlaneDeparture,
  faPen,
  faTrash,
  faEnvelope,
  faChevronUp,
  faChevronDown,
  faUserSlash,
  faPlay,
  faTree,
  faCopy,
  faSleigh,
  faGift,
  faSkiing,
  faFileInvoice,
  faCloudUploadAlt,
  faFileContract,
  faCheck,
  faSnowflake,
  faQrcode
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
  faUser,
  faSort,
  faSortUp,
  faSortDown,
  faSync,
  faEye,
  faBan,
  faTimes,
  faArrowLeft,
  faSave,
  faPlus,
  faPencilAlt,
  faBars,
  faHome,
  faThList,
  faUserPlus,
  faRoad,
  faTachometerAlt,
  faHeart,
  faList,
  faBell,
  faTasks,
  faBook,
  faHdd,
  faFlag,
  faWrench,
  faLock,
  faCloud,
  faSignOutAlt,
  faSignInAlt,
  faCalendarAlt,
  faSearch,
  faTrashAlt,
  faAsterisk,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faSpinner,
  faBolt,
  faPlaneDeparture,
  faPen,
  faTrash,
  faEnvelope,
  faChevronUp,
  faChevronDown,
  faUserSlash,
  faPlay,
  faTree,
  faCopy,
  faSleigh,
  faGift,
  faSkiing,
  faFileInvoice,
  faCloudUploadAlt,
  faFileContract,
  faCheck,
  faSnowflake,
  faQrcode
];
