import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class CustomerNameHolder {
  profileName: string;

  public constructor(init?: Partial<CustomerNameHolder>) {
    Object.assign(this, init);
  }
}

export class CustomerProfiles {
  id: string;
  brand: string;
  brandId: string;
  name: string;
  customerProfileId: string;
  customerProfileCode: string;
  brandIcon: string;
  brandIconContentType: string;
  commonCustomerName: string;

  public constructor(init?: Partial<CustomerProfiles>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerNameAdapter implements Adapter<CustomerNameHolder> {
  adapt(item: any): CustomerNameHolder {
    return new CustomerNameHolder({
      profileName: item?.name
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerProfilesAdapter implements Adapter<CustomerProfiles> {
  adapt(item: any): CustomerProfiles {
    return new CustomerProfiles({
      id: item?.id,
      brand: item?.brand,
      brandId: item?.brandId,
      name: item?.name,
      customerProfileId: item?.customerProfileId,
      customerProfileCode: item?.customerProfileCode,
      brandIcon: item?.brandIcon,
      brandIconContentType: item?.brandIconContentType,
      commonCustomerName: item?.commonCustomerName
    });
  }
}
