import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-control-error-message',
  template: `
    <mat-error class="is-valid" [class.is-invalid]="control && control.invalid && control.touched">{{ errorMessage }}</mat-error>
  `,
  styles: ['mat-error { margin-top: 5px; } .is-valid { display: none; } .is-invalid { display: block; }']
})
export class FormControlErrorMessageComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input() public control: AbstractControl;
  @Input() public label: string;
  @Input() public labelKey: string;
  public errorMessage: string;

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.subscription = this.control?.statusChanges.pipe(startWith(null)).subscribe(() => {
      if (this.control.errors) {
        if (this.labelKey) {
          this.label = this.translateService.instant(this.labelKey);
        }
        if (this.control.errors.required) {
          this.errorMessage = `${this.label} ${this.translateService.instant('is required')}`;
        } else if (this.control.errors.email) {
          this.errorMessage = `${this.label} ${this.translateService.instant('is not valid')}`;
        } else if (this.control.errors.invalid) {
          this.errorMessage = `${this.label} ${this.translateService.instant('is not valid')}`;
        } else if (this.control.errors['pattern']) {
          this.errorMessage = `${this.label} ${this.translateService.instant('is not correct')}`;
        } else if (this.control.errors['minlength']) {
          this.errorMessage = `${this.label} ${this.translateService.instant('must be at least')} ${
            this.control.errors['minlength'].requiredLength
          } ${this.translateService.instant('characters')}`;
        } else if (this.control.errors['maxlength']) {
          this.errorMessage = `${this.label} ${this.translateService.instant('exceeds max length of')} ${
            this.control.errors['maxlength'].requiredLength
          } ${this.translateService.instant('characters')}`;
        } else if (this.control.errors['custom']) {
          this.errorMessage = `${this.label} ${this.translateService.instant(this.control.errors['custom'])}`;
        } else {
          this.errorMessage = JSON.stringify(this.control.errors);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
