import { HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings.service';

export class BaseService {
  apiURL = ''; // 'api/';
  baseURL: string;
  jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/JSON' });

  constructor(protected settingService: SettingsService) {
    this.baseURL = this.settingService.getSupouiEndpoint() + this.apiURL;
  }
}
