import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@supoui/core/auth/account.service';
import { LoginService } from '@supoui/core/login/login.service';
import { SettingsService } from '@supoui/core/services/settings.service';
import { Account } from '@supoui/core/user/account.model';
import { NeedHelpLoggingInModalComponent } from '@supoui/shared/components/need-help-logging-in-modal/need-help-logging-in-modal.component';
import { RequestAccountModalComponent } from '@supoui/shared/login/request-account-modal/request-account-modal.component';
import { PageType } from '@supoui/shared/components/info-bar/info-bar.component';

@Component({
  selector: 'jhi-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginModalComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false }) username?: ElementRef;
  @ViewChild('oneTimePin', { static: false }) oneTimePin?: ElementRef;

  authenticationError = false;
  serviceSessionError: string | null;

  oneTimePinRequested = false;

  isDevelopment = false;

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    oneTimePin: ['', [Validators.required]],
    rememberMe: [false]
  });

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private settingService: SettingsService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig
  ) {
    modalConfig.backdrop = 'static';
  }

  ngOnInit(): void {
    this.isDevelopment = this.settingService.isLocal();
    this.serviceSessionError = localStorage.getItem('service-session-error');
    if (this.serviceSessionError) {
      localStorage.removeItem('service-session-error');
      localStorage.removeItem(PageType.FRONT_PAGE);
      localStorage.removeItem(PageType.LAST_PAGE);
    }
  }

  ngAfterViewInit(): void {
    if (this.username) {
      this.username.nativeElement.focus();
    }
  }

  cancel(): void {
    this.authenticationError = false;
    this.loginForm.patchValue({
      username: '',
      password: '',
      oneTimePin: ''
    });
    this.activeModal.dismiss('cancel');
  }

  get mailMyPinEnabled(): boolean {
    const values = this.loginForm.value;
    return values.username && values.password;
  }

  requestOneTimePin(): void {
    this.oneTimePinRequested = false;
    this.authenticationError = false;
    const values = this.loginForm.value;
    this.accountService.requestOneTimePin(values.username).subscribe(() => {
      this.oneTimePinRequested = true;
      if (this.oneTimePin) {
        this.oneTimePin.nativeElement.focus();
      }
    });
  }

  get signInEnabled(): boolean {
    const values = this.loginForm.value;
    return values.username && values.password && values.oneTimePin;
  }

  login(): void {
    this.loginService
      .login({
        username: this.loginForm.get('username')!.value,
        password: this.loginForm.get('password')!.value,
        oneTimePin: this.loginForm.get('oneTimePin')!.value,
        rememberMe: this.loginForm.get('rememberMe')!.value
      })
      .subscribe(
        (account: Account | null) => {
          this.authenticationError = false;
          this.activeModal.close();
          if (
            this.router.url === '/account/register' ||
            this.router.url.startsWith('/account/activate') ||
            this.router.url.startsWith('/account/reset/')
          ) {
            this.router.navigate(['']);
          }
        },
        () => {
          this.authenticationError = true;
          this.oneTimePinRequested = false;
          this.loginForm.patchValue({ oneTimePin: '' });
        }
      );
  }

  register(): void {
    this.activeModal.dismiss('to state register');
    this.router.navigate(['/account/register']);
  }

  registerSupplierAccount(): void {
    const modalRef = this.modalService.open(RequestAccountModalComponent, {
      size: 'lg',
      //      centered: true,
      scrollable: true
    });
    modalRef.result.then(result => {
      // this.urlLocation.back();
    });
  }

  requestResetPassword(): void {
    this.activeModal.dismiss('to state requestReset');
    this.router.navigate(['/auth/reset', 'request']);
  }

  needHelpLoggingIn(): void {
    this.modalService.open(NeedHelpLoggingInModalComponent, {
      size: 'xl'
    });
  }

  quickSignIn(value: string): void {
    this.loginForm.patchValue({
      username: value,
      password: value,
      oneTimePin: '1234',
      rememberMe: true
    });
    this.login();
  }

  navigateToNewCase(): void {
    this.router.navigate(['new-case-unauthorized']);
  }

  navigateToInvoice(): void {
    this.router.navigate(['invoice']);
  }
}
