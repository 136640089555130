<app-environment-ribbon [isAuthenticated]="false"></app-environment-ribbon>
<div class="login-container" [class.authentication-error-height]="authenticationError || serviceSessionError">
    <div class="col-md-12 pl-0">
        <h5 class="title mb-3">Sign in with your Supplier account</h5>
    </div>

    <div class="row justify-content-center login-container-inner pb-3">
        <div class="col-md-12 pt-2">
            <div class="alert alert-danger mb-0" *ngIf="authenticationError" jhiTranslate="login.messages.error.authentication">
                <strong>Failed to sign in!</strong> Please check your username, password and request a new one time pin and try again.
            </div>
            <div class="alert alert-danger mb-0" *ngIf="serviceSessionError">
                <strong>{{serviceSessionError}}</strong>
            </div>
        </div>

        <div class="col-md-12 pt-3">
            <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="form-group">
                    <label class="username-label" for="username" jhiTranslate="global.form.username.label">Login</label>
                    <input type="text" class="form-control" name="username" id="username" placeholder="{{ 'global.form.username.placeholder' | translate }}"
                           formControlName="username" #username>
                </div>

                <div class="form-group">
                    <label for="password" jhiTranslate="login.form.password">Password</label>
                    <input type="password" class="form-control" name="password" id="password" placeholder="{{ 'login.form.password.placeholder' | translate }}"
                           formControlName="password">
                </div>

                <label for="oneTimePin" jhiTranslate="login.form.oneTimePin">One time pin</label>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <button type="button" class="primary w-100" mat-stroked-button jhiTranslate="login.form.mailMyPin" (click)="requestOneTimePin()" [disabled]="signInEnabled || oneTimePinRequested || !mailMyPinEnabled" title="{{!mailMyPinEnabled ? ('login.form.mailMyPin.requiredFields' | translate) : (oneTimePinRequested ? ('login.messages.onetimepin.requested' | translate) : '')}}">{{'Mail my pin' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">S -</div>
                            </div>
                            <input type="password" class="form-control" name="oneTimePin" id="oneTimePin" placeholder="{{ 'login.form.oneTimePin.placeholder' | translate }}"
                                   formControlName="oneTimePin" minlength="4" maxlength="4" autocomplete="off" #oneTimePin>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe">
                    <label class="form-check-label" for="rememberMe">
                        <span jhiTranslate="login.form.rememberme">Remember me</span>
                    </label>
                </div>

                <div class="form-group">
                    <button type="submit" class="primary" mat-stroked-button jhiTranslate="login.form.signin" [disabled]="!signInEnabled" title="{{!signInEnabled ? ('login.form.signin.requiredFields' | translate) : ''}}">Login</button>
                    <button *ngIf="isDevelopment" type="button" class="primary" mat-stroked-button (click)="quickSignIn('admin')">admin</button>
                    <button *ngIf="isDevelopment" type="button" class="primary" mat-stroked-button (click)="quickSignIn('user')">user</button>
                    <a class="forgot-password-link alert-link" (click)="requestResetPassword()" jhiTranslate="login.password.forgot">Forgot your password?</a>
                </div>
            </form>
        </div>

        <div class="col-md-12 pt-3">
            <span jhiTranslate="global.messages.info.register.noaccount">Don't have a supplier account?</span> <a class="forgot-password-link alert-link" (click)="registerSupplierAccount()" jhiTranslate="global.messages.info.register.link">Register one here</a>
        </div>

<!--        <div class="need-help-logging-in col-md-12 pt-5" (click)="needHelpLoggingIn()">-->
<!--            <div class="title">{{'Need help logging in?' | translate}}</div>-->
<!--            <div class="img-container">-->
<!--                <img src="/content/images/how-to-login.png" width="260" height="145">-->
<!--                <fa-icon class="fa-play" icon="play" size="4x"></fa-icon>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
<div class="action-container" [class.authentication-error-margin-top]="authenticationError || serviceSessionError">
    <div class="col-md-12">
        <h5 class="title mb-3">{{'Submit case without log in' | translate }}</h5>
    </div>
    <div class="col-md-12">
        <div class="action-item" (click)="navigateToNewCase()">
            <div fxFill fxLayout="row" fxLayoutGap="8px">
                <div fxLayoutAlign="center start">
                    <img src="/content/icons/edit.png" width="38" height="38">
                </div>
                <div fxLayoutAlign="start center">
                    <div fxLayout="column">
                        <div class="name">{{'Report new case' | translate}}</div>
                        <div class="title">{{'Request GOP' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="action-container" [class.authentication-error-margin-top]="authenticationError || serviceSessionError">-->
<!--    <div fxFill fxLayout="row" fxLayoutGap="20px">-->
<!--        <div fxFlex="50" fxLayoutAlign="center center">-->
<!--            <div class="action-item" fxLayout="column" fxLayoutAlign="center center" (click)="navigateToNewCase()">-->
<!--                <div>-->
<!--                    <h3>{{'Report new case' | translate}}</h3>-->
<!--                </div>-->
<!--                <div fxLayout="row" fxLayoutGap="25px">-->
<!--                    <div fxFlex="60" fxLayoutAlign="start">-->
<!--                        <fa-icon icon="list" size="4x"></fa-icon>-->
<!--                    </div>-->
<!--                    <div fxFlex="40" fxLayoutAlign="start center">-->
<!--                        GOP<br>Invoice-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div fxFlex="50" fxLayoutAlign="center center">-->
<!--            <div class="action-item-disabled" fxLayout="column" fxLayoutAlign="center center">&lt;!&ndash;  (click)="navigateToInvoice()" &ndash;&gt;-->
<!--                <div class="disabled-ribbon">coming soon</div>-->
<!--                <div>-->
<!--                    <h3>{{'Submit invoice' | translate}}</h3>-->
<!--                </div>-->
<!--                <div fxLayout="row" fxLayoutGap="15px">-->
<!--                    <div fxFlex="50" fxLayoutAlign="start">-->
<!--                        <fa-icon icon="file-invoice" size="4x"></fa-icon>-->
<!--                    </div>-->
<!--                    <div fxFlex="50" fxLayoutAlign="start center">-->
<!--                        Existing case-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="need-help-logging-in view-lt-sm" [class.authentication-error-margin-top]="authenticationError" (click)="needHelpLoggingIn()" fxShow="true" fxHide.gt-sm>-->
<!--    <div class="title">{{'Need help logging in?' | translate}}</div>-->
<!--    <div class="img-container">-->
<!--        <img src="/content/images/how-to-login.png" width="260" height="145">-->
<!--        <fa-icon class="fa-play" icon="play" size="4x"></fa-icon>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="need-help-logging-in view-gt-sm" (click)="needHelpLoggingIn()" fxHide="true" fxShow.gt-sm>-->
<!--    <div class="title">{{'Need help logging in?' | translate}}</div>-->
<!--    <div class="img-container">-->
<!--        <img src="/content/images/how-to-login.png" width="260" height="145">-->
<!--        <fa-icon class="fa-play" icon="play" size="4x"></fa-icon>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="new-supplier-container" fxHide="true" fxShow.gt-sm>-->
<!--    <div fxLayout="row" fxLayoutAlign="center center">-->
<!--        <span class="title">{{'I want to be a supplier at SOS' | translate}}</span>-->
<!--    </div>-->
<!--</div>-->
